
import { Row, Col, PageHeader, Button } from 'antd'
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
const HeaderPage = ({ showModal, CargarDatosTabla }) => {
    const navigate = useNavigate()
    return (
        <Row type="flex"  >
            <Col className="gutter-row" xs={24} sm={24} md={12} lg={20} xl={20}>
                <PageHeader
                    className="site-page-header-responsive"
                    onBack={() => navigate('/dashboard')}
                    title="Colores"
                    subTitle="Listado De Colores"
                />
            </Col>
            <Col className="gutter-row" justify="center" align="middle" xs={24} sm={24} md={3} lg={4} xl={4}>
                <Button onClick={CargarDatosTabla} shape="circle" style={{marginRight: '5px'}}>
                    <ReloadOutlined />
                </Button>
                <Button style={{ marginTop: '20px' }} onClick={showModal} type="primary" shape="round" icon={<PlusOutlined />} >
                    Agregar
                </Button>
            </Col>
        </Row>
    )
}

export default HeaderPage