import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Steps, Divider } from 'antd';

import './ActualizaProductos.css'

import ContainerUpdateDetails from './FormActualizarProductos/ContainerUpdateDetails'
import FormActualizarProducto from './FormActualizarProductos/FormActualizarProducto'

const { Step } = Steps;

const ActualizarProducto = () => {
  const { id } = useParams()
  const [current, setCurrent] = useState(0);

  const setToCurrent = (index) => {
    setCurrent(index);
  }

  const steps = [
    {
      title: 'Actualizar Producto',
      content: <FormActualizarProducto idProducto={id} />
    },
    {
      title: 'Actualizar Detalles del Producto',
      content: <ContainerUpdateDetails setToCurrent={setToCurrent} idProducto={id} />
    }
  ];
  
  

  return (
    <>
      <Steps current={current}>
        {steps.map((item, index) => (
          <Step 
            className="pasosSteper" 
            key={item.title} 
            onClick={() => {
              setToCurrent(index)
            }}  
            title={item.title} />
        ))}
      </Steps>
      <Divider />
      <div className="steps-content">{steps[current].content}</div>
    </>
  )
}

export default ActualizarProducto