import { PageHeader, Form, Input, Button, Select } from 'antd';
import React, {useState, useEffect} from 'react';
import { helpAxios } from '@helpers/helpAxios'
import { useNavigate } from 'react-router-dom'
import FormAddDetail from '../Actualizar/NewUpdateFormProduct/FormAddDetail'
import { showAlert } from '@helpers/helpers'

const { Option } = Select;

const AgregarProducto = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [tipoProducto, setTipoProducto] = useState([])
    const [ prefix, setPrefix] = useState('')
    const [idProductoInsertado, setIdProductoInsertado] = useState(null)

    const onFinishProducto = (value) => {
        value.prefix = prefix
        helpAxios().post('/productos', value)
        .then(({ error, message, type, data }) => {
            if(error){
                showAlert(type, message)
            }else{
                setIdProductoInsertado(data)
                const addRowProductDetail = document.getElementById('addRowProductDetail')
                console.log(addRowProductDetail)
                addRowProductDetail.click()
            }
        })
    };

    useEffect(() => {
        document.title = "Agregar Producto"
        helpAxios().get('/productos/cargaInicial')
            .then(({ data }) => {
                setTipoProducto(data.tipoProducto)
            })
    }, []);

    const getTipoItem = (id) => {
        const { nombreTipo } = tipoProducto.filter(f => f.idTipoProducto == id)[0]
        const prefijo = nombreTipo.substring(0, 3).toUpperCase()
        setPrefix(prefijo)
    }

    return (
        <>
            <PageHeader
                className="site-page-header"
                onBack={() => navigate('/')}
                title="Productos"
                subTitle="Agregar producto"
            />
            
            <Form
                form={form}
                onFinish={onFinishProducto}
                layout="vertical"
            >
                <Form.Item label="Nombre" name="nombreProducto" rules={[{ required: true, message: 'Debes ingresar un nombre a tu producto' }]}>
                    <Input placeholder="Nombre producto" disabled={ idProductoInsertado ? true: false} />
                </Form.Item>
                <Form.Item label="Tipo producto" name="tipoProducto" rules={[{ required: true, message: 'Debes seleccionar un tipo de producto' }]}>
                    <Select
                        placeholder="Selecciona un tipo de producto"
                        allowClear
                        disabled={ idProductoInsertado ? true: false}
                        onChange={getTipoItem}
                        >
                        {tipoProducto.map( tp => 
                            <Option key={tp.idTipoProducto} value={tp.idTipoProducto}>{tp.nombreTipo}</Option>
                        )}
                    </Select>
                </Form.Item>
                    <Form.Item label="SKU Seller" name="skuSeller" rules={[{ required: true, message: 'Debes ingresar un precio a tu producto' }]}>
                    <Input prefix={prefix+' -'} maxLength={3} minLength={3} placeholder="Código SKU Seller" disabled={ idProductoInsertado ? true: false} />
                </Form.Item>
                <Form.Item label="Precio" name="precioProducto" rules={[{ required: true, message: 'Debes ingresar un precio a tu producto' }]}>
                    <Input prefix="$" placeholder="Precio producto" disabled={ idProductoInsertado ? true: false} />
                </Form.Item>
                <Form.Item >
                    <Button type="primary" style={{boxShadow: '0 1px 8px #1088e9'}} htmlType="submit" disabled={ idProductoInsertado ? true: false} >Continuar</Button>
                </Form.Item>
            </Form>
            {idProductoInsertado && 
                <FormAddDetail 
                    tipoAccion="insert" 
                    idProducto={idProductoInsertado} 
                />
            }
        </>
    )
}

export default AgregarProducto
