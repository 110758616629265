import { Empty } from 'antd'

const EmptyDataTabla = ({message}) => {
    return (
        <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60}}
            description={
                <span>
                    {message}
                </span>
            }
        />
        
    )
}

export default EmptyDataTabla