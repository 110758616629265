import { useState, useEffect } from 'react'
import { FileExcelOutlined } from '@ant-design/icons';

import { helpAxios } from '@helpers/helpAxios'
import TablaPerfiles from '@components/Global/TablaClickSecundario/Tabla'
import Header from '@components/Global/HeaderTable/Header'
import Footer from './Listado/Footer/Footer'
import Acciones from './Listado/Acciones/Acciones'
import PageHeader from './Listado/PageHeader/PageHeader'
import ModalAgregar from './Agregar/Agregar'
import ModalActualizar from './Editar/Editar'
import { exportTable, capitalize, showAlert } from '@helpers/helpers'

const Perfiles = () => {
    const [perfilData, setPerfilData] = useState([])

    const [initialPerfilData, setInitialPerfilData] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [modalVisible, setModalVisible] = useState(false)
    const [modalUpdateVisible, setModalUpdateVisible] = useState(false)


    const columns = [
        {
            title: 'ID Perfil',
            dataIndex: 'id_usuario_tipo',
            key: 'id_usuario_tipo',
            width: '33%',
            sorter: (a, b) => a.id_usuario_tipo - b.id_usuario_tipo
        },
        {
            title: 'Perfil',
            dataIndex: 'descripcion',
            key: 'descripcion',
            width: '33%',
            sorter: (a, b) => a.descripcion.localeCompare(b.descripcion),
            render: (text) => capitalize(text),
        },
        {
            title: 'Estado',
            dataIndex: 'estado_perfil',
            key: 'estado_perfil',
            width: '33%',
            sorter: (a, b) => a.estado_perfil.localeCompare(b.estado_perfil),
            render: (text) => capitalize(text),
        },
    ];

    useEffect(() => {
        getPerfilData()
    }, []);

    const getPerfilData = () => {
        helpAxios().get('/perfil')
            .then(({ error, type, message, data }) => {
                if (error) {
                    showAlert(type, message)
                } else {
                    setPerfilData(data)
                    setInitialPerfilData(data)
                }
            })
    }

    const searchInput = (inserted) => {
        const filteredData = initialPerfilData.filter(entry =>
            String(entry.id_usuario_tipo).toLowerCase().includes(inserted.toLowerCase()) ||
            entry.descripcion.toLowerCase().includes(inserted.toLowerCase())
        );
        setPerfilData(filteredData);
    }

    const handleEnableDisable = (id, estado) => {
        let data = {
            idUser: id,
            estado
        }
        helpAxios().post('/perfil/delete', data)
        .then(({type, message }) => {
            showAlert(type, message)
            getPerfilData()
        })
    }

    const menuItems = [
        { key: 1, icon: <FileExcelOutlined />, label: 'Exportar', onClick: () => exportTable(columns, initialPerfilData, 'Datos perfiles', 'Perfiles.xlsx') }
    ];

    return (
        <>
            <PageHeader modalVisible={() => setModalVisible(true)}/>
            <TablaPerfiles
                keyTabla="id_usuario_tipo"
                Acciones={Acciones}
                showModalUpdate={()=> setModalUpdateVisible(true)}
                handleEnableDisable={handleEnableDisable}
                Header={Header}
                menuItems={menuItems}
                Footer={Footer}
                searchInput={searchInput}
                columns={columns}
                dataTabla={perfilData}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
            />
            <ModalAgregar 
                isModalVisible={modalVisible}
                hideModal={()=> setModalVisible(false)}
                getTableData={getPerfilData}
                selectedData={selectedData}
            />
            <ModalActualizar
                isModalVisible={modalUpdateVisible}
                hideModal={()=> setModalUpdateVisible(false)}
                getTableData={getPerfilData}
                selectedData={selectedData}
            />
        </>
    )
}

export default Perfiles