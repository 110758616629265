import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input, Select, Grid, Button, Row, Col, Modal, notification } from 'antd';
import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'

import ColorPicker from '@components/Global/ColorPicker/ColorPicker'

const { useBreakpoint } = Grid;
const { Option } = Select;

const FormAddDetail = ({ idProducto, tipoAccion }) => {
  const screens = useBreakpoint();
  const navigate = useNavigate()
  const [pantalla, setPantalla] = useState('xxl')
  const [datos, setDatos] = useState([])
  const [tallasProductos, setTallasProductos] = useState([]);
  const [colores, setColores] = useState([]);
  useEffect(() => {
    document.title = "Actualizando detalles"

    helpAxios().get('/productos/tallas')
      .then(({ data }) => {
        setTallasProductos(data.tallasProducto)
      })

    helpAxios().get('/productos/colores')
      .then(({ data }) => {
        setColores(data.colores)
      })


  }, []);

  useEffect(() => {
    let dataScreens = Object.entries(screens)
    let newArray = []
    dataScreens.map((data, index) => {
      newArray.push({ id: index, valor: data[0], activo: data[1] })
      return true
    })
    let ids = newArray.filter(f => f.activo === true).map(dato => dato.id)
    let activo = Math.max(...ids)
    let pantallaActiva = newArray.filter(f => f.id === activo).map(dato => dato.valor)
    setPantalla(pantallaActiva[0])
  }, [screens])

  const addDetailData = () => {
    if (tipoAccion === 'insert') {
      handleSaveData()
    } else {
      handleSaveDataUpdate()
    }
  }

  const handleDatosAdd = () => {
    setDatos([...datos, {
      index: datos.length + 1,
      talla: null,
      cantidad: null,
      color: null,
      errorTalla: true,
      errorCantidad: true,
      errorColor: true,
    }])
  }
  const handleSaveDataUpdate = () => {
    let verificar = datos.some(f => f.errorTalla === true || f.errorCantidad === true || f.errorColor === true)
    if (!verificar) {
      let data = {
        idProducto: idProducto,
        datosDetalle: datos
      }
      helpAxios().post('/productos/agregar/detalle', data)
        .then(({ error, type, message }) => {
          if (error) {
            showAlert(type, message)
          } else {
            navigate('/productos')
          }

        })
    } else {
      showAlert('error', 'Verifica todos los datos para continuar.')
    }
  }

  const handleSaveData = () => {
    let verificar = datos.some(f => f.errorTalla === true || f.errorCantidad === true || f.errorColor === true)
    if (!verificar) {
      let data = {
        idProducto: idProducto,
        datosDetalle: datos
      }
      helpAxios().post('/productos/agregar/detalle', data)
        .then(({ error, type, message }) => {
          if (error) {
            notification[type]({
              message: message,
              placement: 'bottomLeft'
            });
          } else {
            Modal.confirm({
              title: 'Quieres agregar otro producto ?',
              onOk() {
                window.location.reload()
              },
              onCancel() {
                navigate('/productos')
              }
            });
          }

        })
    } else {
      showAlert('error', 'Verifica todos los datos para continuar.')
    }
  }

  const handleChangeStock = (event, index) => {
    let { target } = event
    let datosGuardar = datos
    datosGuardar[index]['cantidad'] = target.value
    datosGuardar[index]['errorCantidad'] = target.value === '' || target.value === null ? true : false
    setDatos([...datosGuardar])
  }
  const handleChangeTalla = (value, index) => {
    let datosGuardar = datos
    datosGuardar[index]['talla'] = value
    datosGuardar[index]['errorTalla'] = value === '' || value === null || typeof value === 'undefined' ? true : false
    setDatos([...datosGuardar])
  }
  const handleChangeColor = ({ target }, index) => {
    const { value } = target
    let datosGuardar = datos
    let color = [...colores]
    let nombreColor = color.filter(f => f.hexadecimal === value)[0].nombre
    datosGuardar[index]['color'] = value
    datosGuardar[index]['nombreColor'] = nombreColor
    datosGuardar[index]['errorColor'] = value === '' || value === null || typeof value === 'undefined' ? true : false
    setDatos([...datosGuardar])

  }
  const handleRemoveData = (index) => {
    let filteredData = datos.filter(f => f.index !== index)
    setDatos(filteredData)
  }


  return (
    <>
      <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Col>
          <Button
            id="addRowProductDetail"
            type="primary"
            shape='circle'
            style={{ marginBottom: '15px' }}
            onClick={handleDatosAdd}
            icon={<PlusOutlined />}
          />
        </Col>
      </Row>
      {datos.map((data, key) => (
        <Row
          type="flex"
          key={data.index}
          style={{ 
            marginBottom: '15px',
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Col align={pantalla === 'xs' ? 'left' : 'center'} xs={24} lg={1} xl={1}>
            <label style={{ fontSize: 'bold', color: '#000' }}><span style={{ color: 'red' }}>*</span>Talla:</label>
          </Col>
          <Col justify="center" xs={24} lg={4} xl={4}>
            <Select
              status={data.errorTalla ? 'error' : ''}
              placeholder="Selecciona una talla"
              allowClear
              style={{ minWidth: '163px', width: '100%', borderColor: 'red' }}
              onChange={(data) => handleChangeTalla(data, key)}
            >
              {tallasProductos.map(tp =>
                <Option key={tp.nombreTalla} value={tp.nombreTalla}>{tp.nombreTalla}</Option>
              )}
            </Select>
          </Col>
          <Col align={pantalla === 'xs' ? 'left' : 'center'} xs={24} lg={2} xl={2}>
            <label style={{ fontSize: 'bold', color: '#000' }}><span style={{ color: 'red' }}>*</span>Cantidad:</label>
          </Col>
          <Col xs={24} lg={3} xl={3}>
            <Input
              type="number"
              style={{ width: '100%', }}
              status={data.errorCantidad ? 'error' : ''}
              placeholder="Stock"
              onChange={(e) => handleChangeStock(e, key)}
            />

          </Col>
          <Col align={pantalla === 'xs' ? 'left' : 'center'} xs={24} lg={1} xl={1}>
            <label style={{ fontSize: 'bold', color: '#000' }}><span style={{ color: 'red' }}>*</span>Color:</label>
          </Col>
          <Col align="center" xs={24} lg={6} xl={6}>
            <ColorPicker
              handleChangeColor={(e) => handleChangeColor(e, key)}
              index={key}
            />
          </Col>
          <Col xs={2} lg={2} xl={2} justify="center">
            <Button
              type="primary" danger
              onClick={() => handleRemoveData(data.index)}
              icon={<DeleteOutlined />}
            />
          </Col>
        </Row>
      ))}
      {datos.length > 0 &&
        <Row justify="center">
          <Col>
            <Button type="primary" onClick={addDetailData}>Guardar detalles</Button>
          </Col>
        </Row>
      }
    </>
  )
}

export default FormAddDetail