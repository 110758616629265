import { Button, Modal, Form, Input } from 'antd';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'
const Agregar = ({isModalVisible, hideModal, getTableData}) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    let dataInsert ={
      descripcion: values.descripcion
    }
    helpAxios().put('/perfil', dataInsert)
    .then(({ error, type, message }) => {
      if(!error){
        form.resetFields();
        getTableData()
        hideModal()
      }
      showAlert(type, message)
    })
  };


  return (
    
    <Modal title="Agregar perfil" visible={isModalVisible} footer={null} onCancel={hideModal}>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Perfil"
          name="descripcion"
          rules={[
            {
              required: true,
              message: 'Ingresa un perfil.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Agregar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Agregar