import { Button, Modal, Form, Input } from 'antd';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'

const Agregar = ({ isModalVisible, hideModal, getTableData }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        helpAxios().post('/tallas/agregar', values)
        .then(({ error, type, message}) => {
            showAlert(type, message)
            if(!error){
                form.resetFields();
                hideModal()
                getTableData()
            }  
        })
    };


    return (

        <Modal title="Agregar talla" footer={null} visible={isModalVisible} onCancel={hideModal} >
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: false,
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="Talla"
                    name="nombreTalla"
                    rules={[
                        {
                            required: true,
                            message: 'Ingrese talla.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button style={{marginRight:"10px"}} type="primary" htmlType="submit">
                        Agregar 
                    </Button>
                    <Button onClick={hideModal}>
                        Cancelar
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default Agregar