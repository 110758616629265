import { Table, Menu, Row, Col, PageHeader, Button} from 'antd'
import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { utils, writeFile } from 'xlsx'
import EmptyDataTabla from '@components/Global/EmptyTabla/EmptyDataTabla';
import { helpAxios } from '@helpers/helpAxios'
import PopUpMenu from './Menu/Menu'


import FooterUsers from './Footer/FooterUsers'
import HeaderUsers from './Header/HeaderUsers'

const Users = () => {
    const [usersData, setUsersData] = useState([])
    const [popUpMenuVisible, setPopUpMenuVisible] = useState(false)
    const [initialUsersData, setInitialUsersData] = useState([])
    const [selectedData, setSelectedData] = useState({})
    let [x, setX] = useState(false)
    let [y, setY] = useState(false)

    const navigate = useNavigate()
    
    const menuItems = [
        { key: 1, icon: <FileExcelOutlined />, label: 'Exportar', onClick: () => exportTable()}
    ];
    const menu = (
        <Menu 
            items={menuItems}
        />
    );
    const columns = [
        {
            title: 'Nombre usuario',
            dataIndex: 'userName',
            key: 'userName',
            sorter: (a, b) => a.userName.localeCompare(b.userName)
        },
        {
            title: 'Nombre',
            dataIndex: 'us_nombre',
            key: 'us_nombre',
            sorter: (a, b) => a.us_nombre.localeCompare(b.us_nombre),
            
        },
        {
            title: 'Apellido',
            dataIndex: 'us_apellido',
            key: 'us_apellido',
            align: 'center',
            sorter: (a, b) => a.us_apellido.localeCompare(b.us_apellido)
        },
        {
            title: 'Fullname',
            dataIndex: 'us_fullname',
            key: 'us_fullname',
            sorter: (a, b) => a.us_apellido.localeCompare(b.us_fullname)
        },
        {
            title: 'Tipo usuario',
            dataIndex: 'tipoUsuario',
            key: 'tipoUsuario',
            sorter: (a, b) => a.tipoUsuario - b.tipoUsuario
        },
        {
            title: 'Estado',
            dataIndex: 'estadoUsuario',
            key: 'estadoUsuario',
            sorter: (a, b) => a.estadoUsuario - b.estadoUsuario
        },
    ];

    useEffect(() => {
        getUsersData()
    }, []);

    const getUsersData = () => {
        let datos = {
            tipo: '1'
        }
        helpAxios().post('/users/getUsers', datos)
        .then(({ error, type, message, data}) => {
            if(error){
                notification[type]({
                    message: message,
                    placement: 'bottomLeft'
                });
            }else{
                setUsersData(data)
                setInitialUsersData(data)
            }
        })
    }

    const searchInput = (inserted) => {
        const filteredData = initialUsersData.filter(entry =>
            entry.userName.toLowerCase().includes(inserted.toLowerCase()) || 
            entry.us_nombre.toLowerCase().includes(inserted.toLowerCase()) ||
            entry.us_apellido.toLowerCase().includes(inserted.toLowerCase()) ||
            entry.us_fullname.toLowerCase().includes(inserted.toLowerCase()) 
        );
        setUsersData(filteredData);
    }

    const exportTable = () => {
        let arrayToExport = []
        usersData.forEach((item)=> {
            arrayToExport.push({
                "Nombre usuario": item.userName,
                "Nombre": item.us_nombre,
                "Apellido": item.us_apellido,
                "Nombre Completo": item.us_fullname,
                "Tipo usuario": item.us_tipo === 1 ? 'Administrador':'Normal',
                "Admin": item.isAdmin
            })
            
        })
        const wb = utils.book_new()
        const ws = utils.json_to_sheet(arrayToExport)
        utils.book_append_sheet(wb,ws,'Datos usuario')
        writeFile(wb, 'Usuarios.xlsx')
    }

    const onDelete = (id) => {
        let data = {
            idUser: id
        }
        helpAxios().del('/users', data)
        .then(() => {
            getUsersData()
        })
    }

    return (
        <>
            <Row type="flex"  >
                <Col className="gutter-row" xs={24} sm={24} md={12} lg={20} xl={20}>
                    <PageHeader
                        className="site-page-header-responsive"
                        onBack={() => navigate('/dashboard')}
                        title="Usuarios"
                        subTitle="Listado de usuarios"
                    />
                </Col>
                <Col className="gutter-row" justify="center" align="middle" xs={24} sm={24} md={3} lg={4} xl={4}>
                    <Button style={{ marginTop: '20px' }} onClick={() => navigate('/usuario/agregar')} type="primary" shape="round" icon={<PlusOutlined />} >
                        Agregar
                    </Button>
                </Col>
            </Row>
            <Table
                style={{marginTop: '15px'}}
                rowKey='userName'
                columns={columns}
                dataSource={usersData}
                bordered
                rounded
                scroll={{ x: 1300 }}
                onRow={(data) => {
                    return {
                        onContextMenu: event => {
                            event.preventDefault()
                            if (!popUpMenuVisible) {
                                document.addEventListener(`click`, function onClickOutside() {
                                    setPopUpMenuVisible(false)
                                    document.removeEventListener(`click`, onClickOutside)
                                })
                            }
                            setPopUpMenuVisible(true)
                            setSelectedData(data)
                            setX(event.clientX)
                            setY(event.clientY)
                        },
                    };
                }}
                locale={{
                    emptyText: (<EmptyDataTabla message="No hay ventas encontradas."/>),
                    triggerDesc: 'Ordenar Descendentemente',
                    triggerAsc: 'Ordenar Ascendentemente', 
                    cancelSort: 'Cancelar ordenamiento'
                }}
                title={() =>(
                    <HeaderUsers 
                        searchInput={searchInput}
                        menu={menu}
                    />
                )}
                footer={() =>(
                    <FooterUsers 
                        totalUsers={usersData.length}
                    />
                )}
            />
            <PopUpMenu 
                visible={popUpMenuVisible} 
                data={selectedData} 
                x={x} 
                y={y} 
                onDelete={onDelete}
            />
        </>
    )
}

export default Users