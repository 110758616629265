import { Column } from "@ant-design/plots";

const DemoColumn = ({datosGrafico}) => {

  const config = {
    data: datosGrafico,
    xField: "type",
    yField: "value",
    seriesField: "",
    color: () => "#" + Math.floor(Math.random() * 16777215).toString(16),
    label: {
      content: (originData) => {
        const val = parseFloat(originData.value);

        if (val < 0.05) {
          return (val * 100).toFixed(1) + "%";
        }
      },
      offset: 10,
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  return <Column {...config} />;
};

export default DemoColumn;
