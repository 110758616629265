import { Divider } from 'antd';
import EditData from './EditData/EditData'
import EditPassword from './EditPassword/EditPassword'
const Editar = () => {
  return (
    <div>
        <EditPassword />
            <Divider />
        <EditData />
    </div>
  )
}

export default Editar