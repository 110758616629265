import { Tag } from 'antd';
import { useState, useEffect } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import { helpAxios } from '@helpers/helpAxios'
import AgregarColor from '../Agregar/Agregar'
import ActualizarColor from '../Actualizar/Actualizar';
import HeaderPage from './HeaderPage/HeaderPage'
import TablaPerfiles from '@components/Global/TablaClickSecundario/Tabla'
import Acciones from './Acciones/Acciones'
import Header from '@components/Global/HeaderTable/Header'
import Footer from './Footer/Footer';

import { exportTable } from '@helpers/helpers'

const columns = [
  {
    title: 'Id',
    dataIndex: 'id_color',
    key: 'id_color',
    width: '33%',
    sorter: (a, b) => a.id_color - b.id_color
  },
  {
    title: 'Nombre',
    dataIndex: 'nombre',
    key: 'nombre',
    width: '33%',
  },
  {
    title: 'Color',
    key: 'hexadecimal',
    dataIndex: 'hexadecimal',
    width: '33%',
    render: (hexadecimal) => (
      <Tag style={{
        minWidth: '100px',
        backgroundImage: hexadecimal === '#tiedye' ?
          "linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)" :
          hexadecimal === '#animalprint' ? "url('https://dbdzm869oupei.cloudfront.net/img/vinylrugs/preview/31862.png')" : '',
        backgroundSize: 'cover',
        color: hexadecimal === '#ffffff' ?
          'black' : 'white',
        border: hexadecimal === '#ffffff' ?
          '1px solid black' : '1px solid ' + hexadecimal
      }}
        color={hexadecimal}
        key={hexadecimal}>
        &nbsp;
      </Tag>
    ),
  },
];

const Colores = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUpdateVisible, setIsUpdateVisible] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(0);
  let [datosSeleccionados, setDatosSeleccionados] = useState({})
  let [colores, setColores] = useState([]) //arreglo
  let [initialColorsData, setInitialColorsData] = useState([]) //arreglo

  useEffect(() => {
    document.title = "Colores"
    CargarDatosTabla()
  }, []);

  const CargarDatosTabla = () => {
    setLoading(true)
    helpAxios().get('/colores')
      .then(({ data }) => {
        setColores(data)
        setInitialColorsData(data)
        setLoading(false)
      })
  };
  const onDelete = id => {

    helpAxios().del('/colores/' + id)
    .then(() => {
        CargarDatosTabla()
    })
}
  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };
  const showModalUpdate = () => {
    setIsUpdateVisible(true);
  };

  const hideModalUpdate = () => {
    setIsUpdateVisible(false);
  };
  const handleDataUpdate = (data) => {
    setDataUpdate(data)
  }

  const searchInput = (inserted) => {
    const filteredData = initialColorsData.filter(entry =>
        String(entry.id_color).toLowerCase().includes(inserted.toLowerCase()) ||
        entry.nombre.toLowerCase().includes(inserted.toLowerCase()) ||
        entry.hexadecimal.toLowerCase().includes(inserted.toLowerCase())
    );
    setColores(filteredData);
}

  const menuItems = [
    { key: 1, icon: <FileExcelOutlined />, label: 'Exportar', onClick: () => exportTable(columns, initialColorsData, 'Datos perfiles', 'Perfiles.xlsx') }
];
  return (
    <>
      <HeaderPage 
        showModal={showModal}
        CargarDatosTabla={CargarDatosTabla}
      />
      <AgregarColor
        isModalVisible={isModalVisible}       ///pasarlos al otro componente
        hideModal={hideModal}
        CargarDatosTabla={CargarDatosTabla}
      />
      <ActualizarColor
        isUpdateVisible={isUpdateVisible}       ///pasarlos al otro componente
        hideModalUpdate={hideModalUpdate}
        CargarDatosTabla={CargarDatosTabla}
        idToUpdate={dataUpdate}
      />
      <TablaPerfiles
        keyTabla="id_color"
        Acciones={Acciones}
        Header={Header}
        menuItems={menuItems}
        loading={loading}
        Footer={Footer}
        searchInput={searchInput}
        columns={columns}
        dataTabla={colores}
        setSelectedData={setDatosSeleccionados}
        selectedData={datosSeleccionados}
        onDelete={onDelete}
        handleDataUpdate={handleDataUpdate}
        showModalUpdate={showModalUpdate}
      />
    </>
  )
}

export default Colores