import axios from 'axios'
import { config } from "../genericos/config";
import { message } from 'antd';

const baseURL = config.urlApi

export const helpAxios = () => {
    const customFetch = (url, data, method) => {
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
            
        }
        if(localStorage.getItem("auth")) headers["x-access-token"] = localStorage.getItem("auth")

        return axios({ url, baseURL, method, data, headers,})
            .then(({data}) =>
                data.messageToken ? 
                message.loading(data.messageToken, 1)
                .then(() => {
                    localStorage.removeItem("auth")
                    window.location.href = '/login'
                }) 
                : data
            )
            .catch((err) => {
                console.log(err)
            });
    };

    const get = (url, data, method = 'GET') => customFetch(url, data, method);

    const post = (url, data, method = 'POST') => {
        return customFetch(url, data, method);
    };

    const put = (url, data, method = 'PUT') => {
        return customFetch(url, data, method);
    };

    const del = (url, data, method = 'DELETE') => {
        return customFetch(url, data, method);
    };

    return {
        get,
        post,
        put,
        del,
    };
};