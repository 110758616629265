// const Menu = ({ Acciones, visible, x, y, selectedData, onDelete, handleDataUpdate, showModalUpdate, handleEnableDisable }) => {
const Menu = ({ Acciones, ...args }) => {
    return args.visible &&
        <ul className="popup" style={{ left: `${args.x}px`, top: `${args.y}px` }}>
            <Acciones 
                {...args}
            />
        </ul>
}

export default Menu