import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { capitalize } from '@helpers/helpers'
const { confirm } = Modal;
const Acciones = ({ ...args }) => {
  console.log('selectedData',args.selectedData)
  return (
    <>
      <li onClick={() => args.showModalUpdate()}>
        <i className="fa-solid fa-pen" style={{ marginRight: 5, color: 'blue' }}></i>Editar
      </li>
      <li onClick={() => {
            confirm({
                title: `¿Estás seguro que deseas eliminar la talla ${capitalize(args.selectedData.nombreTalla)}?`,
                icon: <ExclamationCircleOutlined />,
                content: '',
                okText: 'Si',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    args.eliminarTallas(args.selectedData.idTalla)
                },
            });
        }}>
          <i className="fa-solid fa-trash" style={{ marginRight: 5, color: 'red' }}></i> Eliminar
      </li>
    </>
  )
}
export default Acciones