import styled from 'styled-components'
import { useState, useEffect } from 'react';
import { helpAxios } from '@helpers/helpAxios'
const InputColor = styled.input`
                      -webkit-appearance: none !important;
                      width: 20px !important;
                      height: 20px !important;
                      border-radius: 50% !important;
                      outline: none !important;
                      background-image: ${props => props.colorSeleccionar === '#tiedye' ? 'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)' : 
                      props.colorSeleccionar === '#animalprint' ? 'url("https://dbdzm869oupei.cloudfront.net/img/vinylrugs/preview/31862.png")' : ''};
                      background-size: cover;
                      /* background-image: linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet) : url('https://dbdzm869oupei.cloudfront.net/img/vinylrugs/preview/31862.png'): ''  */
                      border: 3px solid ${props => props.colorSeleccionar === '#ffffff' ? 'black' : props.colorSeleccionar};
                      transition: 0.2s !important;
                      background-color: ${props => props.colorSeleccionar === '#000000' ? 'black' : 'white'};
                      margin-right: 10px !important;
                      &:before {
                        content: '';
                        display: block !important;
                        width: 60% !important;
                        height: 60% !important;
                        margin: 20% auto !important;
                        border-radius: 50% !important;
                      }
                      &:checked:before{
                        display: flex !important;
                        justify-content: center !important;
                        align-items: center !important;
                        transform: translate(0, -18%) !important;
                        border-radius: 50% !important;
                        content: '✔' !important;
                        font-weight: bold;
                        color: ${props => props.colorSeleccionar === '#ffffff' ? 'black' : 'white'} !important;
                        background: ${props => props.colorSeleccionar};
                        width: 22px !important;
                        height: 22px !important;
                      }
                      &:checked{
                        border-color: ${props => props.colorSeleccionar === '#ffffff' ? 'black' : props.colorSeleccionar};
                        width: 30px !important;
                        height: 30px !important;
                        transition: 0.2s !important;
                        background: ${props => props.colorSeleccionar};

                      }
`

const ColorPicker = ({ colorProducto, handleChangeColor, index }) => {

  const [colores, setColores] = useState([]);

  useEffect(() => {
    helpAxios().get('/productos/colores')
        .then(({ data }) => {
          setColores(data.colores)
        })
  }, []);

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
        {colores.map( (color, key) => {
          if(colorProducto === color.hexadecimal){
            return <InputColor 
                      key={key} 
                      name={'colorProducto'+index} 
                      type="radio" 
                      title={color.nombre} 
                      colorSeleccionar={color.hexadecimal}
                      defaultChecked
                      value={color.hexadecimal}
                      onChange={handleChangeColor}
                    />
          }else{
            return <InputColor 
                      key={key} 
                      name={'colorProducto'+index} 
                      type="radio" 
                      title={color.nombre} 
                      colorSeleccionar={color.hexadecimal}
                      value={color.hexadecimal}
                      onChange={handleChangeColor}
                    />
          }
        })}
    </div>
  )
}

export default ColorPicker