import { Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import './Menu.css'

const { confirm } = Modal;

const Popup = ({ data, visible, x, y, onDelete }) => {

    const navigate = useNavigate()

    return visible &&
        <ul className="popup" style={{ left: `${x}px`, top: `${y}px` }}>
            <li onClick={() => navigate(`/usuario/${data.userName}`)}>
                <EditOutlined style={{ marginRight: 5, color: 'blue' }} /> Editar
            </li>
            {data.estadoUsuario === 'Habilitado' &&
                <li
                    onClick={() => {
                        confirm({
                            title: `Estas seguro que deseas deshabilitar el usuario ${data.us_fullname}?`,
                            icon: <ExclamationCircleOutlined />,
                            content: '¡El usuario no podra acceder al sistema!',
                            okText: 'Si',
                            okType: 'danger',
                            cancelText: 'No',
                            onOk() {
                                onDelete(data.idUser)
                            },
                            onCancel() {
                            },
                        });
                    }}
                >
                    <FontAwesomeIcon icon={solid('user-xmark')} style={{ marginRight: 5, color: 'red' }} />
                    Deshabilitar
                </li>
            }
            {data.estadoUsuario === 'Deshabilitado' &&
                <li
                    onClick={() => {
                        confirm({
                            title: `Estas seguro que deseas habilitar el usuario ${data.us_fullname}?`,
                            icon: <ExclamationCircleOutlined />,
                            content: '¡El usuario volverá a tener acceso al sistema!',
                            okText: 'Si',
                            okType: 'danger',
                            cancelText: 'No',
                            onOk() {
                                onDelete(data.idUser)
                            },
                            onCancel() {
                            },
                        });
                    }}
                ><FontAwesomeIcon icon={solid('user-check')} style={{ marginRight: 5, color: 'green' }} /> Habilitar
                </li>
            }
        </ul>
}

export default Popup