import { notification } from 'antd'
import { utils, writeFile } from 'xlsx'

export const formatMiles = (dato) => {
    // eslint-disable-next-line use-isnan
    if (dato !== NaN && dato !== null) {
        let aux = ''
        if (dato < 0) {
            aux = '-'
            dato *= -1
        }
        let num;
        let valor = dato.toString()
        num = valor.replace(/\./g, ",");
        num = num.toString().split("").reverse().join("").replace(/(?=\d*\.?)(\d{3})/g, "$1.");
        // eslint-disable-next-line no-useless-escape
        num = num.split("").reverse().join("").replace(/^[\.]/, "");
        return aux + num
    }
    return ''
}

export const showAlert = (type, message) => {
  let colors = {
    error: 'red',
    success: '#1890ff',
    warning: '#fd7e14'
  }
  let icons = {
    error: 'fa-solid fa-triangle-exclamation',
    success: 'fa-solid fa-circle-check',
    warning: 'fa-solid fa-circle-exclamation'
  }

  return (
    notification.open({
        message: (
          <span style={{color: 'white'}}><i className={icons[type]} style={{ marginRight: 20}}></i>{message}</span>
        ),
        duration: 3,
        placement: 'bottomLeft',
        closeIcon : (<div></div>),
        style: {
          backgroundColor: colors[type],
          borderRadius: '10px',
          minWidth: 390,
          maxWidth: 390,
          paddingTop: 15,
          paddingBottom: 10
        },
    })
  )
}
export const capitalize = (word) => {
  const lower = word.toLowerCase()
  return word.charAt(0).toUpperCase() + lower.slice(1)
}
export const exportTable = (columns, data, sheetName, fileName) => {
  let arrayToExport = []

  data.forEach((item) => {
    let dataToAdd = {}
    columns.forEach( col => {
      dataToAdd[col.title] = item[col.key]
    })
    arrayToExport.push(dataToAdd)

  })
  const wb = utils.book_new()
  const ws = utils.json_to_sheet(arrayToExport)
  utils.book_append_sheet(wb, ws, sheetName)
  writeFile(wb, fileName)
}