import { useState, useEffect } from 'react'
import { Table, Input, Button, Dropdown, Row, Col, Menu, Tag, Badge, Tooltip, PageHeader, Empty, Modal } from 'antd';
import { MoreOutlined, FileExcelOutlined, PlusOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { helpAxios } from '@helpers/helpAxios'
import { formatMiles } from '@helpers/helpers'
import { utils, writeFile } from 'xlsx'
import Popup from './ClickSecundario/Popup'
import ContenedorAgregarVenta from '@components/Gestores/Ventas/Agregar/ContenedorAgregar'
import './Productos.css'

import Barcode from 'react-barcode'


const Productos = () => {
    const menuItems = [
        { key: 1, icon: <FileExcelOutlined />, label: 'Exportar', onClick: () => exportTable()}
    ];
    const [value, setValue] = useState('');
    let [menuTablaVisible, seMenuTablaVisible] = useState(false)
    let [x, setX] = useState(false)
    let [y, setY] = useState(false)
    let [datosSeleccionados, setDatosSeleccionados] = useState({})
    let [datosTabla, setDatosTabla] = useState([])
    let [loadingTabla, setLoadingTabla] = useState(true)
    let [datosTablaOriginal, setDatosTablaOriginal] = useState(true)
    let [detailData, setDetailData] = useState({})
    let [modalVisible, setModalVisible] = useState(false)
    let [agregarVentaVisible, setAgregarVentaVisible] = useState(false)
    const navigate = useNavigate()

    const columns = [
        {
            title: 'Código barra',
            dataIndex: 'codigoBarra',
            key: 'codigoBarra',
            sorter: (a, b) => a.codigoBarra - b.codigoBarra,
            render: (codigoBarra) => 
                <div style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                    <Barcode
                        fontSize="12px"
                        height="30px" 
                        value={codigoBarra} />
                </div>
        },
        {
            title: 'SKU Seller',
            dataIndex: 'SKU',
            key: 'SKU',
            sorter: (a, b) => a.SKU.localeCompare(b.SKU),
        },
        {
            title: 'Nombre',
            dataIndex: 'pr_nombre',
            key: 'pr_nombre',
            sorter: (a, b) => a.pr_nombre.localeCompare(b.pr_nombre),
        },
        {
            title: 'Tipo',
            dataIndex: 'pr_tipoProducto',
            key: 'pr_tipoProducto',
            sorter: (a, b) => a.pr_tipoProducto.localeCompare(b.pr_tipoProducto),
        },
        {
            title: 'Talla/Color/Stock',
            dataIndex: 'detalles',
            key: 'detalles',
            align: 'center',
            render: (detalles, data) =>  
                            //Operador ternario para imprimir colores si es que los trae o imprimir sin stock si es no posee.
                            data.stockProducto > 0 ? 
                            detalles.map((detalle, index) => {
                                return (
                                    <Tooltip placement="topLeft" 
                                        color={detalle.colorProducto} 
                                        style={{
                                            backgroundImage: detalle.colorProducto === '#tiedye' ? 
                                            "linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)" : 
                                            detalle.colorProducto === '#animalprint' ? "url('https://dbdzm869oupei.cloudfront.net/img/vinylrugs/preview/31862.png')": '',
                                            backgroundSize: 'cover' 
                                        }}
                                        title={detalle.colorProducto === '#ffffff' ? 
                                        <span style={{color: '#000000'}}>{detalle.nombre}</span> : 
                                        <span>{detalle.nombre}</span>} key={index}>
                                        <Badge count={detalle.cantidadProducto} showZero>
                                            <Tag style={{ 
                                                marginLeft: '12px', 
                                                fontWeight: 'bold', 
                                                color: detalle.colorProducto === '#ffffff' ? '#000': '#FFF',
                                                boxShadow: detalle.colorProducto === '#ffffff' ? '0 1px 5px #a09292' : 'none',
                                                backgroundImage: detalle.colorProducto === '#tiedye' ? 
                                                "linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)" : 
                                                detalle.colorProducto === '#animalprint' ? "url('https://dbdzm869oupei.cloudfront.net/img/vinylrugs/preview/31862.png')": '',
                                                backgroundSize: 'cover' 
                                                }} 
                                                color={detalle.colorProducto} >
                                                {detalle.tallaProducto}
                                            </Tag>
                                        </Badge>
                                    </Tooltip>
                                );
                            }) 
                            : <span><b>Sin Stock</b></span>
            ,
        },
        {
            title: 'Stock',
            dataIndex: 'stockProducto',
            key: 'stockProducto',
            sorter: (a, b) => a.stockProducto - b.stockProducto,
            render: text => text === null ? 0 : text,
        },
        {
            title: 'Precio',
            dataIndex: 'pr_Precio',
            key: 'pr_Precio',
            sorter: (a, b) => a.pr_Precio - b.pr_Precio,
            render: text => '$' + formatMiles(text),
        }
    ];

    useEffect(() => {
        document.title = "Productos"
        CargarDatosTabla()
    }, []);

    const CargarDatosTabla = () => {
        helpAxios().get('/productos')
            .then(({data}) => {
                setDatosTabla(data)
                setDatosTablaOriginal(data)
                setLoadingTabla(false)
            })
    }

    const handleAddVentaVisible = () => {
        setAgregarVentaVisible(true)
    }
    const handleAddVentaHide = () => {
        setAgregarVentaVisible(false)
    }

    const onDelete = id => {
        let data = {
            idProducto: id
        }
        helpAxios().del('/productos/delete', data)
        .then(() => {
            CargarDatosTabla()
        })
    }
    const exportTable = () => {
        let arrayToExport = []
        console.log(datosTabla)
        datosTabla.forEach((item)=> {
            if(item.detalles.length > 0){
                item.detalles.forEach((detalle) => {
                    arrayToExport.push({
                        "Código Producto": item.codigoBarra,
                        "Nombre Producto": item.pr_nombre,
                        "Tipo Producto": item.pr_tipoProducto,
                        "SKU Seller": item.SKU,
                        "Stock Producto": item.stockProducto,
                        "Precio": '$'+item.pr_Precio,
                        "Talla Producto": detalle.tallaProducto,
                        "Color Producto": detalle.nombre,
                        "Cantidad Producto": detalle.cantidadProducto,
                        "Fecha Creacion": item.pr_FechaCreacion,
                        "Fecha Modificacion": item.pr_FechaModificacion == null ? 'Sin modificar.': item.pr_FechaModificacion,
                        "Usuario Ingreso": item.usuarioIngreso,
                        "Usuario Modificador": item.usuarioModificador == null ? 'Sin modificar.' : item.usuarioModificador,
        
                    })
    
                })
            }else{
                arrayToExport.push({
                    "Código Producto": item.codigoBarra,
                    "Nombre Producto": item.pr_nombre,
                    "Tipo Producto": item.pr_tipoProducto,
                    "SKU Seller": item.SKU,
                    "Stock Producto": item.stockProducto,
                    "Precio": '$'+item.pr_Precio,
                    "Talla Producto": 'Sin detalle.',
                    "Color Producto": 'Sin detalle.',
                    "Cantidad Producto": 'Sin detalle.',
                    "Fecha Creacion": item.pr_FechaCreacion,
                    "Fecha Modificacion": item.pr_FechaModificacion == null ? 'Sin modificar.': item.pr_FechaModificacion,
                    "Usuario Ingreso": item.usuarioIngreso,
                    "Usuario Modificador": item.usuarioModificador == null ? 'Sin modificar.' : item.usuarioModificador,
    
                })
            }
        })
        const wb = utils.book_new()
        const ws = utils.json_to_sheet(arrayToExport)
        utils.book_append_sheet(wb,ws,'Datos productos y detalles')
        writeFile(wb, 'Productos.xlsx')
    }
    const filtrarDatos = (e) => {
        const currValue = e.target.value;
        setValue(currValue);
        const filteredData = datosTablaOriginal.filter(entry =>
            entry.pr_nombre.includes(currValue) || 
            entry.pr_tipoProducto.includes(currValue) ||
            entry.SKU.includes(currValue) ||
            entry.codigoBarra.includes(currValue) 
        );
        setDatosTabla(filteredData);
    }

    const menu = (
        <Menu 
            items={menuItems}
        />
    );

    return (
        <div>
            <Row type="flex"  >
                <Col className="gutter-row" xs={24} sm={24} md={12} lg={20} xl={20}>
                    <PageHeader
                        className="site-page-header-responsive"
                        onBack={() => navigate('/dashboard')}
                        title="Productos"
                        subTitle="Listado de productos"
                    />
                </Col>
                <Col className="gutter-row" justify="center" align="middle" xs={24} sm={24} md={3} lg={4} xl={4}>
                    <Button style={{ marginTop: '20px' }} onClick={() => navigate('/productos/agregar')} type="primary" shape="round" icon={<PlusOutlined />} >
                        Agregar
                    </Button>
                </Col>
            </Row>
            <Modal
                centered
                visible={modalVisible}
                title={detailData.pr_nombre}
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
                footer={null}
            >
                <Row type='flex' align="right" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        Creado:
                    </Col>
                    <Col className="gutter-row" span={12}>
                        {detailData.pr_FechaCreacion}
                    </Col>
                </Row>
                <Row  align="middle"  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        Creado por:
                    </Col>
                    <Col className="gutter-row"  span={12}>
                        {detailData.usuarioIngreso}
                    </Col>
                </Row>
                <Row align="middle" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        Modificado:
                    </Col>
                    <Col className="gutter-row" span={12}>
                        {detailData.pr_FechaModificacion === null ? 'Producto no ha sido modificado' : detailData.pr_FechaModificacion}
                    </Col>
                </Row>
                <Row justify="space-around"  align="middle" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        Modificado por:
                    </Col>
                    <Col className="gutter-row" span={12}>
                        {detailData.usuarioModificador === null ? 'Producto no ha sido modificado' : detailData.usuarioModificador}
                    </Col>
                </Row>
            </Modal>
            <Table
                className='tablaProductos'
                rowClassName='filasProductos'
                onRow={(data) => {
                    return {
                        onDoubleClick: event => {
                            setDetailData(data)
                            setModalVisible(true)
                        },
                        onContextMenu: event => {
                            event.preventDefault()
                            if (!menuTablaVisible) {
                                // const that = this
                                document.addEventListener(`click`, function onClickOutside() {
                                    seMenuTablaVisible(false)
                                    document.removeEventListener(`click`, onClickOutside)
                                })
                            }
                            seMenuTablaVisible(true)
                            setDatosSeleccionados(data)
                            setX(event.clientX)
                            setY(event.clientY)
                        },
                    };
                }}
                locale={{
                    emptyText: (
                        <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{
                                height: 60,
                            }}
                            description={
                                <span>
                                    No hay datos
                                </span>
                            }
                        >
                        </Empty>),
                    triggerDesc: 'Ordenar Descendentemente',
                    triggerAsc: 'Ordenar Ascendentemente', 
                    cancelSort: 'Cancelar ordenamiento'
                }}
                bordered
                rounded
                style={{marginTop: 15}}
                loading={loadingTabla}
                columns={columns}
                pagination={{ position: ['bottomCenter'] }}
                dataSource={datosTabla}
                scroll={{ x: 1300 }}
                title={() =>
                    <Row type="flex" justify="center" align="middle" style={{minWidth: '100%'}} >
                        <Col className="gutter-row" xs={22} sm={22} lg={22} xl={23}>
                            <Input
                                placeholder="Buscar producto"
                                value={value}
                                onChange={e => {
                                    filtrarDatos(e)
                                }
                                }
                            />
                        </Col>
                        <Col className="gutter-row" style={{paddingLeft: '10px' }} xs={2} sm={2} lg={2} xl={1}>
                            <Dropdown overlay={menu}>
                                <Button shape="circle">
                                    <MoreOutlined />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                }
                footer={() => <><b>Total productos: </b>{datosTabla.length}</>}
            />
            <Popup 
                handleAddVentaVisible={handleAddVentaVisible}
                visible={menuTablaVisible} 
                data={datosSeleccionados} 
                x={x} 
                y={y} 
                onDelete={onDelete}
            />
            {agregarVentaVisible &&
                <ContenedorAgregarVenta 
                    title='Agregar venta'
                    CargarDatosTabla={CargarDatosTabla}
                    datosSeleccionados={datosSeleccionados} 
                    addVentaVisibility={agregarVentaVisible}
                    handleAddVentaHide={handleAddVentaHide}
                />
            }
        </div>
    )
}

export default Productos