import { Modal } from 'antd'
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined, CloseSquareOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const Acciones = ({ selectedData, onDelete, handleDataUpdate, showModalUpdate }) => {
    return (
        <>
            {
                selectedData.hexadecimal === '#000000' || selectedData.hexadecimal === '#ffffff' || selectedData.hexadecimal === '#tiedye' || selectedData.hexadecimal === '#animalprint' ?
                    <li>
                        <CloseSquareOutlined style={{ marginRight: 5, color: 'red' }} /> No hay acciones para estos colores.
                    </li>
                    :
                    <>
                        <li onClick={() => {
                            handleDataUpdate(selectedData.id_color)
                            showModalUpdate()
                        }}>
                            <EditOutlined style={{ marginRight: 5, color: 'blue' }} /> Editar
                        </li>
                        <li
                            onClick={() => {
                                confirm({
                                    title: `Estas seguro que deseas eliminar el color ${selectedData.nombre}?`,
                                    icon: <ExclamationCircleOutlined />,
                                    content: '¡Se borrara todo el color!',
                                    okText: 'Si',
                                    okType: 'danger',
                                    cancelText: 'No',
                                    onOk() {
                                        onDelete(selectedData.id_color)
                                    },
                                });
                            }}
                        ><DeleteOutlined style={{ marginRight: 5, color: 'red' }} /> Eliminar
                        </li>
                    </>
            }
        </>
    )
}

export default Acciones