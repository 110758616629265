import { Button, Modal, Form, Input, notification } from 'antd';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'

const Agregar = ({ isModalVisible, hideModal, CargarDatosTabla }) => {

    const onFinish = (values) => {
        helpAxios().post('/colores/agregar', values)
        .then(({ error, type, message}) => {
            showAlert(type, message)
            if(!error){
                hideModal()
                CargarDatosTabla()
            }  
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (

        <Modal title="Agregar Color" footer={null} visible={isModalVisible} onCancel={hideModal} >
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Nombre"
                    name="nombre"
                    rules={[
                        {
                            required: true,
                            message: 'Ingrese nombre del color!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Color"
                    name="hexadecimal"
                    rules={[
                        {
                            required: true,
                            message: 'ingrese Color!',
                        },
                    ]}
                >
                    <input style={{width: '100%'}} type="color"/>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button style={{marginRight:"10px"}} type="primary" htmlType="submit">
                        Agregar 
                    </Button>
                    <Button onClick={hideModal}>
                        Cancelar
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default Agregar

//onOk={handleOk} onCancel={handleCancel}