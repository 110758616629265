import { Row, Col } from 'antd'
const FooterUsers = ({totalUsers}) => {
    return (
        <Row>
            <Col span={16}>
                <b>Total usuarios: </b>{totalUsers}
            </Col>
        </Row>
    )
}

export default FooterUsers