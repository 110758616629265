import { Button, Form, Input, Select, Switch, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'

const { Option } = Select;

const Perfil = () => {
    const [form] = Form.useForm();
    const { id } = useParams()
    const [userData, setUserData] = useState({ us_nombre: '', us_apellido: '', us_tipo: '' });
    const [ tipoUser, setTipoUser ] = useState('0')


    useEffect(() => {
        document.title = "Actualizando perfil"
        const getUserData = () => {
            const { idPerfil } = JSON.parse(localStorage.getItem('userData'))
            setTipoUser(idPerfil)
            helpAxios().get('/users/' + id)
                .then(({ data }) => {
                    setUserData({ ...data })
                })
        }
        getUserData()

    }, []);


    const onFinish = (values) => {
        values.userName = id
        helpAxios().post('/users/edit', values)
            .then(({ message, type }) => {
                showAlert(type, message)
        })

    }
    return (
        <Form
            layout="vertical"
            name="complex-form"
            onFinish={onFinish}
            fields={[
                { name: ["nombre"], value: userData.us_nombre },
                { name: ["apellido"], value: userData.us_apellido },
                { name: ["tipoUsuario"], value: String(userData.us_tipo) }
            ]}
        >
            <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[
                            {
                                required: true,
                                message: 'Campo nombre es requerido.',
                            },
                        ]}
                    >
                        <Input placeholder="Ingresa tu nombre." />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                    <Form.Item
                        label="Apellido"
                        name="apellido"
                        rules={[
                            {
                                required: true,
                                message: 'Campo apellido es requerido.'
                            }
                        ]}
                    >
                        <Input placeholder="Ingresa tu apellido." />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                    <Form.Item
                        label="Tipo usuario"
                        name="tipoUsuario"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo es requerido.',
                            },
                        ]}
                    >
                        <Select allowClear placeholder="Tipo de usuario" disabled={tipoUser !== 1}>
                            <Option value="1">Administrador</Option>
                            <Option value="2">Normal</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item colon={false}>
                <Button type="primary" htmlType="submit">
                    Actualizar
                </Button>
            </Form.Item>
        </Form>
    );

}

export default Perfil