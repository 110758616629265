import {Row, Card} from 'antd'
import  { useState, useEffect } from "react";
import { helpAxios } from "@helpers/helpAxios";

import Dashboard from './Dashboard'
const DashboardContainer = () => {
  let [datosGrafico, setDatosGrafico] = useState([]);

  useEffect(() => {
    document.title = "Dashboard";
    CargarDatosDashboard();
  }, []);

  const CargarDatosDashboard = () => {
    helpAxios()
      .get("/dashboard")
      .then((res) => {
        setDatosGrafico(res.data);
      });
  };
  return (
    <>
        <Row justify='center'>
          <h2 style={{color: '#1890ff'}}><b>Grafico por tipo de productos.</b></h2>
        </Row>
        <Row justify='center' style={{marginBottom: '30px'}}>
            {datosGrafico.map((data, index) => 
              <Card key={index} style={{ border: '2px solid #cfcfcf', borderRadius: '10px', maxWidth: '300px', marginRight: '15px' }}>
                  <b>{data.type}: </b>
                  <span>{data.value}</span>
              </Card>
            )}
        </Row>
        <Dashboard 
          datosGrafico={datosGrafico}
        />
    </>
  )
}

export default DashboardContainer