import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useState } from 'react'
import Productos from '@components/Gestores/Productos/Productos'
import Perfil from '@components/Arquitectura/Users/Editar/Editar'
import Perfiles from '@components/Arquitectura/Perfiles/Perfiles'
import EditarPerfil from '@components/Arquitectura/Perfiles/Editar/Editar'
import PerfilAsignacion from '@components/Arquitectura/Perfiles/Asignacion/Asignacion'
import AgregarPerfil from '@components/Arquitectura/Perfiles/Agregar/Agregar'
import EditarUsuario from '@components/Arquitectura/Users/Editar/Editar'
import AgregarUsuario from '@components/Arquitectura/Users/Agregar/Agregar'
import Usuarios from '@components/Arquitectura/Users/Lista/Users'
import AgregarProductos from '@components/Gestores/Productos/Agregar/AgregarProducto'
import ActualizarProducto from '@components/Gestores/Productos/Actualizar/ActualizarProducto'
import ReporteVentas from '@components/Reportes/ReporteVentas/ReporteVentas'
import LayoutMenu from '@components/Arquitectura/Layout/LayoutMenu';
import NotFound from '@components/Global/404'
import Login from '@components/Arquitectura/Auth/Login'
import DashboardContainer from '@components/Reportes/Dashboard/DashboardContainer';
import PrivateRoute from '@components/Arquitectura/PrivateRoute'
import Colores from '@components/Gestores/Colores/Listado/Colores';
import TipoProducto from '@components/Arquitectura/Mantenedores/TipoProducto/TipoProducto';
import Tallas from '@components/Arquitectura/Mantenedores/Tallas/Tallas';
import './App.css'


const App = () => {

    const setEstadoAuth = () => {
        let auth = localStorage.getItem('auth')
        if(auth === null || auth === ''){
            return null
        }else{
            return auth
        }
    }
    
    const [idAuth, setAuth] = useState(setEstadoAuth)
    
    const changeAuth = (idauth) => {
        setAuth(idauth)
    }

    return (
            <BrowserRouter>
                <LayoutMenu>
                    <Routes>
                        <Route exact path="/login"  element={<Login changeAuth={changeAuth} />} />
                        <Route exact path="/tipoProducto"  element={<PrivateRoute auth={idAuth} element={TipoProducto} />} />
                        <Route exact path="/tallas"  element={<PrivateRoute auth={idAuth} element={Tallas} />} />
                        <Route exact path="/perfil/:id" element={<PrivateRoute auth={idAuth} element={Perfil} />} />
                        <Route exact path="/perfil/editar/:id" element={<PrivateRoute auth={idAuth} element={EditarPerfil} />} />
                        <Route exact path="/perfil/agregar" element={<PrivateRoute auth={idAuth} element={AgregarPerfil} />} />
                        <Route exact path="/asignacion/perfil/:id" element={<PrivateRoute auth={idAuth} element={PerfilAsignacion} />} />
                        <Route exact path="/perfiles" element={<PrivateRoute auth={idAuth} element={Perfiles} />} />
                        <Route exact path="/dashboard" element={<PrivateRoute auth={idAuth} element={DashboardContainer} />} />
                        <Route exact path="/usuarios" element={<PrivateRoute auth={idAuth} element={Usuarios} />} />
                        <Route exact path="/usuario/:id" element={<PrivateRoute auth={idAuth} element={EditarUsuario} />} />
                        <Route exact path="/usuario/agregar" element={<PrivateRoute auth={idAuth} element={AgregarUsuario} />} />
                        <Route exact path="/productos" element={<PrivateRoute auth={idAuth} element={Productos} />} />
                        <Route exact path="/productos/agregar" element={<PrivateRoute auth={idAuth} element={AgregarProductos} />} />
                        <Route exact path="/productos/actualizar/:id" element={<PrivateRoute auth={idAuth} element={ActualizarProducto} />} />
                        <Route exact path="/ventas" element={<PrivateRoute auth={idAuth} element={ReporteVentas} />} />
                        <Route exact path="/colores" element={<PrivateRoute auth={idAuth} element={Colores} />} />
                        <Route exact path="/perfil/*" element={<PrivateRoute auth={idAuth} element={NotFound} />} />
                        <Route exact path="*" element={<PrivateRoute auth={idAuth} element={NotFound} />} />
                    </Routes>
                </LayoutMenu>
            </BrowserRouter>  
    )
}
export default App