import { useState } from 'react'
import { Table } from 'antd'
import EmptyDataTabla from '@components/Global/EmptyTabla/EmptyDataTabla';
import PopUpMenu from './Menu/Menu'
// const Tabla = ({showModalUpdate, keyTabla, handleEnableDisable, columns, dataTabla, setSelectedData, selectedData, searchInput, onDelete, Acciones, Header, Footer, menuItems, handleDataUpdate}) => {
const Tabla = ({Acciones, Header, Footer, ...args}) => {
    const [popUpMenuVisible, setPopUpMenuVisible] = useState(false)
    let [x, setX] = useState(false)
    let [y, setY] = useState(false)
    return (
        <>
            <Table
                style={{ marginTop: '15px' }}
                rowKey={args.keyTabla}
                columns={args.columns}
                dataSource={args.dataTabla}
                bordered
                rounded
                loading={args.loading}
                scroll={{ x: 1300 }}
                onRow={(data) => {
                    return {
                        onContextMenu: event => {
                            event.preventDefault()
                            if (!popUpMenuVisible) {
                                document.addEventListener(`click`, function onClickOutside() {
                                    setPopUpMenuVisible(false)
                                    document.removeEventListener(`click`, onClickOutside)
                                })
                            }
                            setPopUpMenuVisible(true)
                            args.setSelectedData(data)
                            setX(event.clientX)
                            setY(event.clientY)
                        },
                        onDoubleClick: () => {
                            args.setModalDetalle(true)
                            args.setSelectedData(data)
                        },
                    };
                }}
                locale={{
                    emptyText: (<EmptyDataTabla message="No hay ventas encontradas." />),
                    triggerDesc: 'Ordenar Descendentemente',
                    triggerAsc: 'Ordenar Ascendentemente',
                    cancelSort: 'Cancelar ordenamiento'
                }}
                title={() => (
                    <Header 
                        searchInput={args.searchInput} 
                        menuItems={args.menuItems} 
                    />
                )}
                footer={() => (
                    <Footer {...args} />
                )}
            />
            <PopUpMenu
                Acciones={Acciones}
                x={x}
                y={y}
                visible={popUpMenuVisible}
                {...args}
            />
        </>
    )
}

export default Tabla