import { Button, Modal, Form, Input } from 'antd';
import { useState, useEffect } from 'react';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'

const ActualizarProducto = ({ isUpdateVisible, hideModalUpdate, CargarDatosTabla, idToUpdate }) => {
    const [colorData, setColorData] = useState({nombre: '', hexadecimal: ''});
    const onFinish = (values) => {
        values.id_color = idToUpdate
        helpAxios().post('/colores/updateColor', values)
            .then(({ type, message}) => {
                showAlert(type, message)
                hideModalUpdate()
                CargarDatosTabla()
            })
    }

    useEffect(() => {
        if(isUpdateVisible){
            document.title = "Actualizando Color"
            const obtenerDatosColor = () => {
                helpAxios().get('/colores/' + idToUpdate)
                    .then(({ data }) => {
                        let { dataColor } = data
                        setColorData(dataColor)
                    })
            }
            obtenerDatosColor()
        }
    }, [isUpdateVisible]);

    return (

        <Modal 
            title="Actualizar Producto" 
            footer={null} 
            visible={isUpdateVisible} 
            onCancel={hideModalUpdate} 
        >
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                onFinish={onFinish}
                autoComplete="off"
                fields={[
                    {name: ["nombre"], value: colorData.nombre},
                    {name: ["hexadecimal"], value: colorData.hexadecimal}
                ]}
            >
                <Form.Item
                    label="Nombre"
                    name="nombre"
                    rules={[
                        {
                            required: true,
                            message: 'Ingrese nombre del color!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Color"
                    name="hexadecimal"
                    rules={[
                        {
                            required: true,
                            message: 'ingrese Color!',
                        },
                    ]}
                >
                    <input type="color" style={{width: '100%'}} />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button style={{ marginRight: "10px" }} type="primary" htmlType="submit">
                        Actualizar
                    </Button>
                    <Button onClick={hideModalUpdate}>
                        Cancelar
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )

}

export default ActualizarProducto
