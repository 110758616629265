import { Row, Col, Input, Dropdown, Button,  } from 'antd'
import { MoreOutlined } from '@ant-design/icons';

const HeaderUsers = ({ menu, searchInput }) => {
    return (
        <Row type="flex" justify="center" align="middle" style={{ minWidth: '100%' }} >
            <Col className="gutter-row" xs={22} sm={22} lg={22} xl={23}>
                <Input
                    placeholder="Buscar usuario"
                    onChange={({target}) => searchInput(target.value)}
                />
            </Col>
            <Col className="gutter-row" style={{ paddingLeft: '10px' }} xs={2} sm={2} lg={2} xl={1}>
                <Dropdown overlay={menu}>
                    <Button shape="circle">
                        <MoreOutlined />
                    </Button>
                </Dropdown>
            </Col>
        </Row>
    )
}

export default HeaderUsers