import React, { useState, useEffect } from 'react'
import { Select, Grid, Button,  Row, Col, notification } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { helpAxios } from '@helpers/helpAxios'

const { useBreakpoint } = Grid;
const { Option } = Select;

const FormAddVenta = ({datosSeleccionados, handleCancel, CargarDatosTabla}) => {

  const screens = useBreakpoint();
  const [ dataStock, setDataStock ] = useState([])
  const [ pantalla, setPantalla ] = useState('xxl')
  const [ datos , setDatos] = useState([])

  const obtenerDatos = () => {
    let arrayTallas = []
    datosSeleccionados.detalles.forEach(dato => {
      let contieneTalla = arrayTallas.some( f => f.talla === dato.tallaProducto)
      if(!contieneTalla){
        let colores = datosSeleccionados.detalles.filter( f => f.tallaProducto === dato.tallaProducto).map( data => { return { 
                              colorProducto: data.colorProducto,
                              cantidadProducto: data.cantidadProducto,
                              nombreColor: data.nombre
                            }})
        arrayTallas.push({ talla: dato.tallaProducto, colores: colores})
      }
      setDataStock([...arrayTallas])
    });
    
  }

  
  useEffect(() => {
    obtenerDatos()
  }, []);

  useEffect(() => {
    let dataScreens = Object.entries(screens)
    let newArray = []
    dataScreens.map( (data, index) => {
      newArray.push({ id: index, valor: data[0], activo: data[1] })
      return true
    })
    let ids = newArray.filter( f => f.activo === true).map( dato => dato.id)
    let activo = Math.max(...ids)
    let pantallaActiva = newArray.filter( f => f.id === activo).map( dato => dato.valor)
    setPantalla(pantallaActiva[0])
  }, [screens])


  const handleDatosAdd = () => {
      setDatos([...datos, {
          index: datos.length + 1,
          talla: null,
          color: null,
          cantidad: null,
          dataColores: [],
          dataCantidad: null,
          errorTalla: true,
          errorCantidad: true,
          errorColor: true
      }])
  }
  const addVenta = () => {
    let canSave = handleValidateData()
    let totalVenta = 0
    datos.forEach( data => {
      totalVenta += (data.cantidad*datosSeleccionados.pr_Precio)
    })
    if(canSave){
      let data = {
        totalVenta: totalVenta,
        idProducto: datosSeleccionados.idProducto, 
        valorProducto: datosSeleccionados.pr_Precio,
        datosVenta: datos
      }
      helpAxios().post('/ventas/agregar', data)
        .then(({ type, message }) => {
          notification[type]({
            message: message,
            placement: 'bottomLeft'
          });
          resetForm()
          CargarDatosTabla()
      })
    }else{
      notification['error']({
        message: 'Verifica los datos para continuar.',
        placement: 'bottomLeft'
      });
    }
  }

  const resetForm = () => {
    handleCancel()
    setDatos([])
  }

  const handleChangeTalla = (value, index) => {
    let datosGuardar = datos
    let dataColores = dataStock.filter( f => f.talla === value).map( data => data.colores)[0]
    datosGuardar[index]['talla'] = value
    datosGuardar[index]['color'] = null
    datosGuardar[index]['nombreColor'] = null
    datosGuardar[index]['cantidad'] = null
    datosGuardar[index]['errorTalla'] = value === '' || value === null || typeof value === 'undefined' ? true : false
    datosGuardar[index]['dataColores'] = datosGuardar[index]['errorTalla'] ? [] : [...dataColores]
    setDatos([...datosGuardar])
  }
  const handleChangeColor = (value, index) => {
    let datosGuardar = datos
    let dataCantidad = datosGuardar[index]['dataColores'].filter( f => f.colorProducto === value).map( data => data.cantidadProducto)[0]
    datosGuardar[index]['color'] = value
    let nombreColor = datosGuardar[index]['dataColores'].filter(f => f.colorProducto === value)[0].nombreColor
    datosGuardar[index]['nombreColor'] = nombreColor
    datosGuardar[index]['cantidad'] = null
    datosGuardar[index]['dataCantidad'] = dataCantidad
    datosGuardar[index]['errorColor'] = value === '' || value === null || typeof value === 'undefined' ? true : false
    setDatos([...datosGuardar])
  }
  const handleChangeCantidad = (value, index) => {
    let datosGuardar = datos
    datosGuardar[index]['cantidad'] = value
    datosGuardar[index]['errorCantidad'] = value === '' || value === null || typeof value === 'undefined' ? true : false
    setDatos([...datosGuardar])
  }

  const handleRemoveData = (index) => {
    let filteredData = datos.filter( f => f.index !== index )
    setDatos(filteredData)
  }

  const handleValidateData = () => {
      let datosGuardar = datos
      let contador = 0
      datosGuardar.forEach( item => {
        if(item.errorTalla === true){
          contador++
        }
        if(item.errorCantidad === true){
          contador++
        }
        if(item.errorColor === true){
          contador++
        }
      })
      let canSave = contador === 0 ? true : false
      return canSave
  }
  

  return (
      <>
          <Row justify="center" align="center">
            <Col>
              <Button
                type="primary"
                shape='circle'
                style={{marginBottom: '15px'}}
                onClick={handleDatosAdd}
                icon={<PlusOutlined />}
              />
            </Col>
          </Row>
          {datos.map((data, key)=> (
            <Row 
              type="flex" 
              justify="center"
              gutter={16}
              key={data.index} 
              style={{marginBottom: '15px'}}
            >
                <Col>
                  <Select
                    className="colorRed"
                    value={data.talla}
                    id="talla"
                    status={data.errorTalla ? 'error' : ''}
                    placeholder="Selecciona una talla"
                    allowClear
                    style={{minWidth: '163px', maxWidth: '163px'}}
                    onChange={(data) => handleChangeTalla(data, key)}
                  >
                      {dataStock.map((data, index) =>
                        <Option key={index} value={data.talla}>{data.talla}</Option>
                      )}
                  </Select>
                </Col>
                <Col>
                  <Select
                    id="color"
                    dropdownStyle={{color: 'red'}}
                    value={data.color}
                    status={data.errorTalla ? 'error' : ''}
                    placeholder="Selecciona un color"
                    allowClear
                    style={{ minWidth: '163px', maxWidth: '163px', color: data.color}}
                    onChange={(data) => handleChangeColor(data, key)}
                  >
                      {data.dataColores.map((item, index) => 
                          <Option 
                            colorSeleccionado={data.color}  
                            style={{
                                backgroundColor: item.colorProducto, 
                                color: item.colorProducto,
                                backgroundImage: item.colorProducto === '#tiedye' ? 
                                "linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)" : 
                                item.colorProducto === '#animalprint' ? "url('https://dbdzm869oupei.cloudfront.net/img/vinylrugs/preview/31862.png')": '', 
                                backgroundSize: 'cover'
                            }} 
                            key={index} value={item.colorProducto}
                          >
                              {item.colorProducto}
                          </Option>
                      )}
                  </Select>
                </Col>
                <Col>
                  <Select 
                    id="cantidad"
                    value={data.cantidad}
                    defaultValue={null}
                    status={data.errorTalla ? 'error' : ''}
                    placeholder="Selecciona una cantidad"
                    allowClear
                    style={{minWidth: '163px', maxWidth: '163px'}}
                    onChange={(data) => handleChangeCantidad(data, key)}
                  >
                      {Array.from(Array(data.dataCantidad)).map((_, cantidad) =>
                        <Option key={cantidad} value={cantidad + 1}>{cantidad + 1}</Option>
                      )}
                  </Select>
                </Col>
                <Col>
                  <Button
                    type="primary" danger
                    onClick={() =>handleRemoveData(data.index)}
                    icon={<DeleteOutlined />}
                  />
                </Col>
            </Row>
          ))}
          
              <Row justify="center">
                {datos.length > 0 &&
                  <Col>
                    <Button type="primary" onClick={addVenta}>Guardar detalles</Button>
                  </Col>
                }
                <Col style={{marginLeft: '10px'}}>
                  <Button  onClick={resetForm}>Cancelar</Button>
                </Col>
              </Row>
            
      </>
  )
}

export default FormAddVenta