import { Button, Modal, Form, Input } from 'antd';
import { useState, useEffect } from 'react';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'

const ActualizarProducto = ({ isModalVisible, hideModal, getTableData, selectedData }) => {
    const [form] = Form.useForm();
    const [tipoProductoData, setTipoProductoData] = useState({ ...selectedData });
    const onFinish = (values) => {
        values.idTipoProducto = selectedData.idTipoProducto
        console.log(values)
        helpAxios().post('/tipoProducto/actualizar', values)
        .then(({ error, type, message }) => {
            showAlert(type, message)
            if(!error){
                hideModal()
                getTableData()
            }
        })
    }

    useEffect(() => {
        if(isModalVisible){
            console.log(selectedData)
            setTipoProductoData(selectedData)

        }
    }, [isModalVisible]);

    const onTipoChange = ({ target }) => {
        const { value } = target
        let newData = {
            idTipoProducto: selectedData.idTipoProducto,
            nombreTipo: value
        }
        setTipoProductoData(newData)
        console.log(newData)
    }

    return (

        <Modal 
            title="Actualizar tipo producto" 
            footer={null} 
            visible={isModalVisible} 
            onCancel={() => {
                form.resetFields();
                hideModal()
            }} 
        >
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                onFinish={onFinish}
                autoComplete="off"
                fields={[
                    {name: ["nombreTipo"], value: tipoProductoData.nombreTipo},
                ]}
            >
                <Form.Item
                    label="Tipo producto"
                    name="nombreTipo"
                    rules={[
                        {
                            required: true,
                            message: 'Ingrese tipo producto.',
                        },
                    ]}
                >
                    <Input 
                        onChange={onTipoChange}
                    />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button disabled={selectedData.nombreTipo == tipoProductoData.nombreTipo} style={{ marginRight: "10px" }} type="primary" htmlType="submit">
                        Actualizar
                    </Button>
                    <Button onClick={hideModal}>
                        Cancelar
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )

}

export default ActualizarProducto
