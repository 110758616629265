import { Row, Col} from 'antd'
import { formatMiles } from '@helpers/helpers'

const FooterVentas = ({datosVentas, totalVentas}) => {
    return (
        <Row>
            <Col span={8}>
                <b>Ventas realizadas: </b>{datosVentas.length}
            </Col>
            <Col span={8} offset={8} align="right">
                <b>Total ventas: </b>${formatMiles(totalVentas)}
            </Col>
        </Row>
    )
}
export default FooterVentas