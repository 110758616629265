import { Button, Form, Input, Select, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'

const { Option } = Select;
const Agregar = () => {

    const [ userTypes, setUserTypes] = useState([{id_usuario_tipo: 1, descripcion: ""}])
    const [form] = Form.useForm();
    useEffect(() => {
        document.title = "Actualizando perfil"
        const getUserTipes = () => {
            helpAxios().get('/users/tipo')
                .then(({ data }) => {
                    setUserTypes(data)
                })
        }
        getUserTipes()
    }, []);


    const onFinish = (values) => {
        let dataInsert = {
            userName: values.userName, 
            password: values.passwords.primera, 
            us_nombre: values.us_nombre, 
            us_apellido: values.us_apellido, 
            us_tipo: values.tipoUsuario
        }
        if (values.passwords.primera === values.passwords.repite) {
            helpAxios().put('/users', dataInsert)
                .then(({ message, type }) => {
                    showAlert(type, message)
                    form.resetFields();
                })
        } else {
            showAlert('error', 'Las contraseñas no coinciden.')
        }
    }
    
    return (
        <Form
            form={form}
            layout="vertical"
            name="complex-form"
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                    <Form.Item
                        label="Nombre usuario"
                        name="userName"
                        rules={[
                            {
                                required: true,
                                message: 'Campo nombre es requerido.',
                            },
                        ]}
                    >
                        <Input placeholder="Ingresa un nombre de usuario." />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                    <Form.Item
                        label="Nombre"
                        name="us_nombre"
                        rules={[
                            {
                                required: true,
                                message: 'Campo nombre es requerido.',
                            },
                        ]}
                    >
                        <Input placeholder="Ingresa tu nombre." />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                    <Form.Item
                        label="Apellido"
                        name="us_apellido"
                        rules={[
                            {
                                required: true,
                                message: 'Campo apellido es requerido.',
                            },
                        ]}
                    >
                        <Input placeholder="Ingresa tu apellido." />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col className="gutter-row" span={16}>
                    <Form.Item label="Contraseña">
                        <Input.Group compact>
                            <Form.Item
                                name={['passwords', 'primera']}
                                noStyle
                                rules={[{ required: true, message: 'Campo contraseña es requerido.' }]}
                            >
                                <Input.Password style={{ width: '50%' }} placeholder="Ingresa contraseña." />
                            </Form.Item>
                            <Form.Item
                                name={['passwords', 'repite']}
                                noStyle
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input.Password style={{ width: '50%' }} placeholder="Repite contraseña" />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                    <Form.Item
                        label="Tipo usuario"
                        name="tipoUsuario"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo es requerido.',
                            },
                        ]}
                    >
                        <Select allowClear placeholder="Tipo de usuario">
                            {userTypes.map( item => 
                                <Option key={item.id_usuario_tipo} value={item.id_usuario_tipo}>{item.descripcion}</Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item colon={false}>
                <Button type="primary" htmlType="submit">
                    Agregar
                </Button>
            </Form.Item>
        </Form>
    );
}

export default Agregar




