import { Button, Result } from 'antd';
const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, la pagina que estas queriendo visitar no existe."
      
    />
  )
}

export default NotFound