import { ArrowUpOutlined } from '@ant-design/icons';
import useMediaQuery from '@helpers/useMediaQuery';
import { Layout, BackTop } from 'antd';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import { helpAxios } from '@helpers/helpAxios'
import LeftSideMenu from './LeftSideMenu/LeftSideMenu';
import TopMenu from './TopMenu/TopMenu';
import './LayoutMenu.css'
import { showAlert } from  '@helpers/helpers'
const { Content } = Layout;

const LayoutMenu = ({ children }) => {
  const matches = useMediaQuery('(min-width: 600px)')
  const [leftSideMenus, setLeftSideMenus] = useState([])
  const location = useLocation();

  useEffect(() => {
    if(location.pathname !== '/login'){
      document.title = "Actualizando perfil"
      getAsignacionPerfil()
    }
  }, [location.pathname]);

  const getAsignacionPerfil = () => {
    if(localStorage.getItem('userData')){
      const { idPerfil } = JSON.parse(localStorage.getItem('userData'))
    helpAxios().get('/perfil/asignacion/'+ idPerfil)
      .then(({ error, type, message, data }) => {
        if (error) {
          showAlert(type, message)
        } else {
          let menus = []
          data.map(item => {
            let submenu = []
            let objMenu = {
              key: item.menuLink,
              icon: <i className={item.icon}></i>,
              label: <NavLink to={item.menuLink}>{item.label_menu}</NavLink>
            }
            if (item.submenu === 'SI') {
              item.children.map(subitem => {
                submenu.push({
                  key: item.menuLink + subitem.link,
                  label: <NavLink to={subitem.link}>{subitem.label_link}</NavLink>
                })
              })
              objMenu.children = submenu
              objMenu.label = item.label_menu
            }
            menus.push(objMenu)
          })
          setLeftSideMenus(menus)
        }
      })
    }
  }
  let rutaActual = location.pathname;

  const styleButtonTop = matches ? { transform: 'translateX(60px)' } : {}

  if (rutaActual !== '/login') {
    return (
      <Layout style={{ width: '100%' }}>
        <LeftSideMenu leftSideMenus={leftSideMenus} />
        <Layout>
          <TopMenu />
          <Content style={{ margin: '90px 16px 0', maxWidth: '100vw', height: 'auto', }}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360, maxHeight: '100%', maxWidth: '100%', boxShadow: '3px 2px 42px 2px rgba(0,0,0,0.3)', borderRadius: 15 }}>
              {children}
              <BackTop style={styleButtonTop} >
                <div className="buttonUpToHead"> <ArrowUpOutlined /></div>
              </BackTop>
            </div>
          </Content>
        </Layout>
      </Layout>
    )
  } else {
    return (
      children
    )
  }

}

export default LayoutMenu