import { Grid, Input, Button, Select, Row, Col, notification} from "antd";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { helpAxios } from "@helpers/helpAxios";

import ColorPicker from "@components/Global/ColorPicker/ColorPicker";

const { Option } = Select;
const { useBreakpoint } = Grid;

const FormularioActualizarDetalles = ({
    datosDetalle,
    idProducto,
    tallasProductos,
    ObtenerDatosProducto,
}) => {
    const screens = useBreakpoint();
    
    const [productDetail, setProductDetail] = useState(datosDetalle);
    const [errorTalla, setErrorTalla] = useState(false);
    const [errorCantidad, setErrorCantidad] = useState(false);
    const [errorColor, setErrorColor] = useState(false);
    const [ pantalla, setPantalla ] = useState('xxl')

    useEffect(() => {
        let dataScreens = Object.entries(screens)
        let newArray = []
        dataScreens.map( (data, index) => {
          newArray.push({ id: index, valor: data[0], activo: data[1] })
          return true
        })
        let ids = newArray.filter( f => f.activo === true).map( dato => dato.id)
        let activo = Math.max(...ids)
        let pantallaActiva = newArray.filter( f => f.id === activo).map( dato => dato.valor)
        setPantalla(pantallaActiva[0])
      }, [screens])

    const actualizarDetalles = () => {
        if(errorTalla === false && errorCantidad === false && errorColor === false){
            let data = productDetail
            data.idProducto = idProducto
            helpAxios().post('/productos/updateDetail', data)
            .then(({type, message}) => {
                notification[type]({
                    message: message,
                    placement: 'bottomLeft'
                });
            })
        }else{
            notification['error']({
                message: 'Debes completar todos los datos de la fila.',
                placement: 'bottomLeft'
            });
        }
    };


    const eliminarDetalle = () => {
        helpAxios()
        .del("/productos/deleteDetail", { idPTC: productDetail.idPTC })
        .then(({type, message}) => {
            notification[type]({
                message: message,
                placement: 'bottomLeft'
            });
            ObtenerDatosProducto();
        });
    };

    const handleChangeTalla = async (value) => {
        let newObject = productDetail
        newObject.tallaProducto = value
        let error = value === '' || value === null || typeof value === 'undefined' ? true : false
        setProductDetail(newObject)
        setErrorTalla(error)
    };
    const handleChangeStock = ({target}) => {
        const { value } = target
        let newObject = productDetail
        newObject.cantidadProducto = value
        let error = value === '' || value === null || typeof value === 'undefined' ? true : false
        setProductDetail(newObject)
        setErrorCantidad(error)
        // actualizarDetalles()
    };
    const handleChangeColor = ({target}) => {
        const { value } = target
        let newObject = productDetail
        newObject.colorProducto = value
        let error = value === '' || value === null || typeof value === 'undefined' ? true : false
        setProductDetail(newObject)
        setErrorColor(error)

    };


    return (
        <>
            <Row style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}} justify="center" key={productDetail.idPTC}>
                <Col align="center" xs={24} lg={1} xl={1}>
                    <Button
                        type="primary"
                        onClick={() => actualizarDetalles()}
                        icon={<SaveOutlined />}
                    />
                </Col>
                <Col align={pantalla === 'xs' ? 'left': 'center'} xs={24} lg={1} xl={1}>
                    <label style={{ fontSize: "bold", color: "#000" }}>
                        <span style={{ color: "red" }}>*</span>Talla:
                    </label>
                </Col>
                <Col  justify="center" xs={24} lg={4} xl={4}>
                    <Select
                        status={errorTalla ? "error" : ""}
                        placeholder="Selecciona una talla"
                        allowClear
                        style={{ minWidth: "163px", borderColor: "red" }}
                        onChange={handleChangeTalla}
                        defaultValue={productDetail.tallaProducto}
                    >
                        {tallasProductos.map((tp) => (
                        <Option key={tp.nombreTalla} value={tp.nombreTalla}>
                            {tp.nombreTalla}
                        </Option>
                        ))}
                    </Select>
                </Col>
                <Col align={pantalla === 'xs' ? 'left': 'center'} xs={24} lg={2} xl={2}>
                    <label style={{ fontSize: "bold", color: "#000" }}>
                        <span style={{ color: "red" }}>*</span>Cantidad:
                    </label>
                </Col>
                <Col xs={24} lg={3} xl={3}>
                    <Input
                        status={errorCantidad ? "error" : ""}
                        type="number"
                        defaultValue={productDetail.cantidadProducto}
                        placeholder="Stock"
                        onChange={handleChangeStock}
                    />
                </Col>
                <Col align={pantalla === 'xs' ? 'left': 'center'} xs={24} lg={1} xl={1}>
                    <label style={{ fontSize: "bold", color: "#000" }}>
                        <span style={{ color: "red" }}>*</span>Color:
                    </label>
                </Col>
                <Col  xs={24} lg={6} xl={6}>
                    <ColorPicker
                        colorProducto={productDetail.colorProducto}
                        index={productDetail.idPTC}
                        handleChangeColor={handleChangeColor}
                    />
                </Col>
                <Col align="center" >
                    <Button
                        type="primary"
                        danger
                        onClick={() => eliminarDetalle()}
                        icon={<DeleteOutlined />}
                    />
                </Col>
            </Row>
        </>
    )};
export default FormularioActualizarDetalles;
