
import { Layout, Menu } from 'antd';
import logo from '@public/logo.png'
const { Sider } = Layout;

const LeftSideMenu = ({leftSideMenus}) => {
    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            style={{ zIndex: 3000 }}
        >
            <div className="logo" style={{
                minHeight: 60,
                maxHeight: 60,
                display: 'flex',
                justifyContent: 'center',
                align: 'center'
            }}
            >
                <img
                    src={logo}
                    alt=""
                    style={{ maxHeight: 60, minWidth: 80 }}
                />
            </div>
            <Menu
                theme="dark"
                mode="inline"
                items={leftSideMenus}
            />
        </Sider>
    )
}

export default LeftSideMenu