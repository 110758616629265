import { Modal, Row } from "antd";
import FormAddVenta from './Formularios/FormAddVenta'
const AgregarVenta = ({addVentaVisibility, title, handleAddVentaHide, datosSeleccionados, CargarDatosTabla}) => {
    const handleCancel = () => {
        handleAddVentaHide()
    }

    const getStockDisponble = () => {
        let total = 0
        if( typeof datosSeleccionados.detalles !== 'undefined'){
            datosSeleccionados.detalles.forEach(data => {
                total += data.cantidadProducto
            });
        }
        
        return total
    }
    return (
            <Modal
                title={title}
                visible={addVentaVisibility}
                footer={null}
                closable={false}
                style={{minWidth: "40%"}}
            >
                <Row justify="center" style={{marginBottom: '15px'}}>
                    <table border="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <td><b>Producto:</b></td><td>{datosSeleccionados.pr_nombre}</td>
                                <td><b>Tipo:</b></td><td>{datosSeleccionados.pr_tipoProducto}</td>
                            </tr>
                            <tr>
                                <td><b>Valor:</b></td><td>${datosSeleccionados.pr_Precio}</td>
                                <td><b>Disponible:</b></td><td>{getStockDisponble()}</td>
                            </tr>
                        </thead>
                    </table>
                </Row>
                <FormAddVenta
                    datosSeleccionados={datosSeleccionados} 
                    handleCancel={handleCancel}
                    visibility={addVentaVisibility}
                    CargarDatosTabla={CargarDatosTabla}
                />
        </Modal>
    );
};



export default AgregarVenta;
