import { Table, Tooltip, Tag, Menu} from 'antd'
import { MoreOutlined, FileExcelOutlined } from '@ant-design/icons';
import { formatMiles } from '@helpers/helpers'
import { utils, writeFile } from 'xlsx'
import EmptyDataTabla from '@components/Global/EmptyTabla/EmptyDataTabla';
import FooterVentas from './Footer/FooterVentas'
import HeaderVentas from './Header/HeaderVentas'
const TablaVentas = ({datosVentas, totalVentas}) => {
    const menuItems = [
        { key: 1, icon: <FileExcelOutlined />, label: 'Exportar', onClick: () => exportTable()}
    ];
    const menu = (
        <Menu 
            items={menuItems}
        />
    );
    const columns = [
        {
            title: 'ID Venta',
            dataIndex: 'id_venta',
            key: 'id_venta',
            sorter: (a, b) => a.id_venta - b.id_venta,
        },
        {
            title: 'ID Detalle Venta',
            dataIndex: 'id_detalle_venta',
            key: 'id_detalle_venta',
            sorter: (a, b) => a.id_detalle_venta - b.id_detalle_venta,
        },
        {
            title: 'Tipo Venta',
            dataIndex: 'tipo_venta',
            key: 'tipo_venta',
            sorter: (a, b) => a.tipo_venta.localeCompare(b.tipo_venta),
            
        },
        {
            title: 'Código barra',
            dataIndex: 'codigoBarra',
            key: 'codigoBarra',
            sorter: (a, b) => a.codigoBarra.localeCompare(b.codigoBarra),
            
        },
        {
            title: 'SKU Seller',
            dataIndex: 'SKU',
            key: 'SKU',
            sorter: (a, b) => a.SKU.localeCompare(b.SKU),
            
        },
        {
            title: 'Tipo Pago',
            dataIndex: 'tipo_pago',
            key: 'tipo_pago',
            align: 'center',
            sorter: (a, b) => a.tipo_pago.localeCompare(b.tipo_pago)
        },
        {
            title: 'Monto Total',
            dataIndex: 'monto_total',
            key: 'monto_total',
            sorter: (a, b) => a.monto_total - b.monto_total,
            render: text => '$' + formatMiles(text),
        },
        {
            title: 'Fecha Creacion',
            dataIndex: 'fecha_creacion',
            key: 'fecha_creacion',
            sorter: (a, b) => a.fecha_creacion.localeCompare(b.fecha_creacion)
        },
        {
            title: 'Usuario Operacíon',
            dataIndex: 'us_fullname',
            key: 'us_fullname',
            sorter: (a, b) => a.us_fullname.localeCompare(b.us_fullname),
        },
        {
            title: 'Producto',
            dataIndex: 'pr_nombre',
            key: 'pr_nombre',
            sorter: (a, b) => a.pr_nombre.localeCompare(b.pr_nombre),
        },
        {
            title: 'Cantidad',
            dataIndex: 'producto_cantidad',
            key: 'producto_cantidad',
            sorter: (a, b) => a.producto_cantidad - b.producto_cantidad
        },
        {
            title: 'Color',
            dataIndex: 'color_producto',
            key: 'color_producto',
            render: (color_producto, data) => 
                <Tooltip placement="topLeft" color={color_producto} title={color_producto === '#ffffff' ? <span style={{color: '#000000'}}>{data.nombreColor}</span> : <span>{data.nombreColor}</span>}>
                    <Tag 
                        style={{ 
                            minWidth: '50px',
                            marginLeft: '12px', 
                            fontWeight: 'bold', 
                            color: color_producto === '#ffffff' ? '#000': '#FFF',
                            boxShadow: color_producto === '#ffffff' ? '0 1px 5px #a09292' : 'none',
                            backgroundImage: color_producto === '#tiedye' ? 
                            "linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)" : 
                            color_producto === '#animalprint' ? "url('https://dbdzm869oupei.cloudfront.net/img/vinylrugs/preview/31862.png')": '', 
                            backgroundSize: 'cover',
                            }} 
                            color={color_producto} >
                        &nbsp;
                    </Tag>
                </Tooltip>
        },
        {
            title: 'Valor Producto',
            dataIndex: 'valor',
            key: 'valor',
            sorter: (a, b) => a.valor - b.valor,
            render: text => '$' + formatMiles(text)
        },
    ];

    const exportTable = () => {
        let arrayToExport = []
        datosVentas.forEach((item)=> {
            arrayToExport.push({
                "ID Venta": item.id_venta,
                "Tipo Venta": item.tipo_venta,
                "Tipo Pago": item.tipo_pago,
                "Monto Total": '$' + item.monto_total,
                "Fecha Creacion": item.fecha_creacion,
                "Usuario Operacion": item.us_fullname   ,
                "Producto": item.pr_nombre,
                "Cantidad": item.producto_cantidad,
                "Color": item.nombreColor,
                "Valor Producto": item.valor
            })
            
        })
        const wb = utils.book_new()
        const ws = utils.json_to_sheet(arrayToExport)
        utils.book_append_sheet(wb,ws,'Datos ventas')
        writeFile(wb, 'Ventas.xlsx')
    }

    return (
            <Table
                style={{marginTop: '15px'}}
                rowKey='id_detalle_venta'
                columns={columns}
                dataSource={datosVentas}
                bordered
                rounded
                scroll={{ x: 1300 }}
                locale={{
                    emptyText: (<EmptyDataTabla message="No hay ventas encontradas."/>),
                    triggerDesc: 'Ordenar Descendentemente',
                    triggerAsc: 'Ordenar Ascendentemente', 
                    cancelSort: 'Cancelar ordenamiento'
                }}
                title={() =>(
                    <HeaderVentas 
                        menu={menu}
                    />
                )}
                footer={() =>(
                    <FooterVentas 
                        datosVentas={datosVentas}
                        totalVentas={totalVentas}
                    />
                )}
            />
    )
}

export default TablaVentas