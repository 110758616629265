import { Form, Input, Row, Select, Card, Button, message, Spin } from 'antd';
import React, {useState, useEffect} from 'react';
import { helpAxios } from '@helpers/helpAxios'
import { LoadingOutlined } from '@ant-design/icons';
import { showAlert } from '@helpers/helpers'

const { Option } = Select;

const FormActualizarProducto = ({idProducto}) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true)
    const [tipoProducto, setTipoProducto] = useState([])
    const [productData, setProductData] = useState([])

    useEffect(() => {
        document.title = "Actualizando producto"
        const obtenerTiposProducto = () => {
            helpAxios().get('/productos/cargaInicial')
            .then(({ data }) => {
                setTipoProducto(data.tipoProducto)
            })
        }
        obtenerDatosProducto()
        obtenerTiposProducto()
    }, []);

    const obtenerDatosProducto = () => {
        helpAxios().get('/productos/'+idProducto)
        .then(({data}) => {
            let { dataProduct } = data
            setProductData(dataProduct)
            setLoading(false)
        })
    }

    

    const onFinishProducto = (value) => {
        setLoading(true)
        value.idProducto = idProducto
        helpAxios().post('/productos/updateProduct', value)
        .then(({error, type, message }) => {
            if(!error){
                obtenerDatosProducto()
            }
            setLoading(false)
            showAlert(type, message)

        })
    };
    const LoadinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
        <Row type="flex" justify="center"  align="middle">
            <Spin tip={<span style={{marginTop: '10px'}}>Cargando...</span>} spinning={loading} indicator={LoadinIcon} >
                <Card style={{minWidth: '40vw', borderRadius: 8}}>
                    <Form
                        form={form}
                        onFinish={onFinishProducto}
                        layout="vertical"
                        fields={[
                                {name: ["nombreProducto"], value: productData.pr_nombre},
                                {name: ["tipoProducto"], value: productData.pr_tipoProducto},
                                {name: ["precioProducto"], value: productData.pr_Precio}
                        ]}
                    >
                        
                        <Form.Item label="Nombre" name="nombreProducto" rules={[{ required: true, message: 'Debes ingresar un nombre a tu producto' }]}>
                            <Input placeholder="Inserta nombre" />
                        </Form.Item>
                        <Form.Item label="Tipo producto" name="tipoProducto" rules={[{ required: true, message: 'Debes seleccionar un tipo de producto' }]}>
                            <Select
                                placeholder="Selecciona un tipo de producto"
                                allowClear
                                >
                                {tipoProducto.map( tp => 
                                    <Option key={tp.idTipoProducto} value={tp.idTipoProducto}>{tp.nombreTipo}</Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Precio" name="precioProducto" rules={[{ required: true, message: 'Debes ingresar un precio a tu producto' }]}>
                            <Input maxLength={11} prefix="$" placeholder="Precio producto" />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Spin>
        </Row>
    )
}

export default FormActualizarProducto
