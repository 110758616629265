import { ExclamationCircleOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { Tooltip, Modal } from 'antd';
import { capitalize } from '@helpers/helpers'
const { confirm } = Modal;
const Acciones = ({ ...args }) => {
  const navigate = useNavigate()
  return (
    <>
      {
        args.selectedData.id_usuario_tipo !== 1 &&
        <>
          <li onClick={() => args.showModalUpdate()}>
            <i className="fa-solid fa-pen" style={{ marginRight: 5, color: 'blue' }}></i>Editar
          </li>
          {
            args.selectedData.id_estado_perfil === 1 ? 
              <li onClick={() => {
                confirm({
                    title: `Estas seguro que deseas eliminar el perfil ${capitalize(args.selectedData.descripcion)}?`,
                    icon: <ExclamationCircleOutlined />,
                    content: '¡Se borrara el perfil!',
                    okText: 'Si',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        args.handleEnableDisable(args.selectedData.id_usuario_tipo, 2)
                    },
                });
            }}>
              <i className="fa-solid fa-trash" style={{ marginRight: 5, color: 'red' }}></i> Deshabilitar
            </li>
            :
              <li onClick={() => {
                confirm({
                    title: `Estas seguro que deseas habilitar el perfil ${capitalize(args.selectedData.descripcion)}?`,
                    icon: <ExclamationCircleOutlined />,
                    content: '¡Se volverá a poder utilizar este perfil!',
                    okText: 'Si',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        args.handleEnableDisable(args.selectedData.id_usuario_tipo, 1)
                    },
                });
            }}>
              <i className="fa-solid fa-circle-up" style={{ marginRight: 5, color: 'green' }}></i> Habilitar
            </li>
          }
          <Tooltip placement="right" color="blue" title="Gestor para asignacion de acceso a las distintas vistas del sistema.(Menu lateral)" arrowPointAtCenter>
            <li onClick={() => navigate(`/asignacion/perfil/${args.selectedData.id_usuario_tipo}`)}>
              <i className="fa-solid fa-arrow-right-arrow-left" style={{ marginRight: 5, color: 'blue' }}></i> Asignacion
            </li>
          </Tooltip>
          
        </>
      }
      {
        args.selectedData.id_usuario_tipo === 1 && 
        <li>
          <CloseSquareOutlined style={{ marginRight: 5, color: 'red' }} /> No hay acciones para este perfil.
        </li>
      } 
      

    </>
  )
}
export default Acciones