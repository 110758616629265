import { Row, Col } from 'antd';
import './Login.css';
import FormLogin from './FormLogin/FormLogin'
import useMediaQuery from '@helpers/useMediaQuery'; 
const Login = ({changeAuth}) => {
  const matches = useMediaQuery('(min-width: 600px)')
  if(matches){
    return (
      <Row type="flex" justify="center" align="middle">
          <Col className="leftLogin" xs={15} sm={15} md={15} lg={15} xl={15}>
              <img className="logoImg" src="https://laverdadnoticias.com/__export/1623188636747/sites/laverdad/img/2021/06/08/cuales_son_las_caracteristicas_del_verano_3.jpg_2013006177.jpg" alt="loginLogo" />
          </Col>
          <Col className="rightLogin" xs={9} sm={9} md={9} lg={9} xl={9}>
              <FormLogin changeAuth={changeAuth} />
          </Col>
      </Row>
    )
  }else{
    return (
      <Row type="flex" justify="center" align="middle">
        <FormLogin changeAuth={changeAuth} />
      </Row>
    )
  }
  
}

export default Login