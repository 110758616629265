import { Col, Divider, Row, Switch } from 'antd';
import { showAlert } from '@helpers/helpers'
import { helpAxios } from '@helpers/helpAxios'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
const style = {
  minWidth: '300px'
};

const Asignacion = () => {

  const [dataAsignacion, setDataAsignacion] = useState([])
  const { id } = useParams()
  useEffect(() => {
    document.title = "Actualizando perfil"
    getAsignacion()
  }, []);

  const getAsignacion = () => {
    helpAxios().post('/perfil/asignacion', { id })
      .then(({ error, type, message, data }) => {
        if (error) {
          showAlert(type, message)
        } else {
          setDataAsignacion(data)
        }
      })
  }

  const updateAsignation = (idAsignacion, canEnter, insert ) => {
    helpAxios().put('/perfil/asignacion', { idAsignacion, canEnter, insert, idPerfil: id })
        .then(({ error, type, message }) => {
            if (error) {
              showAlert(type, message)
            }
        })
  }

  const changeStateData = (item, children) => {
    let arregloAsignacion = [...dataAsignacion]
    let canEnter
    let idAsignacion
    let insert
    arregloAsignacion.map( data => {
      if(data.idAsignacionMenu === item.idAsignacionMenu){
        if(item.submenu === 'NO'){
          data.can_enter = !item.can_enter
          canEnter = data.can_enter
          idAsignacion = data.idAsignacionMenu
          insert = data.insert

        }else{
          data.children.map( datoCh => {
            if(datoCh.link === children.link){
              datoCh.can_enter = !children.can_enter
              canEnter = datoCh.can_enter
              idAsignacion = children.idAsignacionMenu
              insert = children.insert
            }
          })
        }
      }
    })
    setDataAsignacion([...arregloAsignacion])
    updateAsignation(idAsignacion, canEnter, insert)
    getAsignacion()
    
  }
  return (
    <>
      {
        dataAsignacion.map(item =>
          <>
            <Row key={item.idAsignacionMenu} style={{border: '1px solid #C1C1C1', borderRadius: '5px', padding: '20px'}}>
              <Col span={24}>
                <Row >
                  <Col style={style} span={1}>
                    <b>{item.label_menu}</b>
                  </Col>
                  {item.submenu === 'NO' &&
                    <Col style={style} span={1}>
                      <Switch onChange={()=> changeStateData(item)} checked={item.can_enter} style={{ marginLeft: '15px' }} />
                    </Col>
                  }
                </Row>
                {item.submenu === 'SI' &&
                  <Row style={{ marginTop: '25px' }}>
                    {
                      item.children.map((children) =>
                        <Col key={children.label_link} style={style} span={12}>
                          <Row>
                            <Col style={style} span={12}>
                              <label htmlFor="">{children.label_link}</label>
                            </Col>
                            <Col style={style} span={12}>
                              <Switch onChange={()=> changeStateData(item, children)} checked={children.can_enter} style={{ marginLeft: '15px' }} />
                            </Col>
                          </Row>
                        </Col>
                      )
                    }
                  </Row>
                }
              </Col>
            </Row>
            <Divider />
          </>
        )
      }
      
    </>

  )
}

export default Asignacion