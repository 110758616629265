import { useState, useEffect } from 'react'
import { FileExcelOutlined } from '@ant-design/icons';

import { helpAxios } from '@helpers/helpAxios'
import TablaTipoProductos from '@components/Global/TablaClickSecundario/Tabla'
import Header from '@components/Global/HeaderTable/Header'
import Footer from './Listado/Footer/Footer'
import Acciones from './Listado/Acciones/Acciones'
import PageHeader from './Listado/PageHeader/PageHeader'
import ModalAgregar from './Agregar/AgregarTalla'
import ModalActualizar from './Actualizar/Actualizar'
import { exportTable, showAlert } from '@helpers/helpers'
import ModalDetalle from './ModalDetalle/ModalDetalle';

const Tallas = () => {
    const [tallasData, setTallasData] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [initialTallasData, setInitialTallasData] = useState([])
    const [modalAddTallas, setModalAddTallas] = useState(false)
    const [modalUpdateTallas, setModalUpdateTallas] = useState(false)
    const [modalDetalle, setModalDetalle] = useState(false)

    const columns = [
        {
            title: 'ID Talla',
            dataIndex: 'idTalla',
            key: 'idTalla',
            width: '33%',
            sorter: (a, b) => a.idTalla - b.idTalla
        },
        {
            title: 'Talla',
            dataIndex: 'nombreTalla',
            key: 'nombreTalla',
            width: '33%',
            sorter: (a, b) => a.nombreTalla.localeCompare(b.nombreTalla)
        }
    ];

    useEffect(() => {
        getTallaData()
    }, []);

    const getTallaData = () => {
        helpAxios().get('/tallas')
        .then(({ error, type, message, data }) => {
            if (error) {
                showAlert(type, message)
            } else {
                setTallasData(data)
                setInitialTallasData(data)
            }
        })
    }

    const searchInput = (inserted) => {
        const filteredData = initialTallasData.filter(entry =>
            String(entry.idTalla).toLowerCase().includes(inserted.toLowerCase()) ||
            entry.nombreTalla.toLowerCase().includes(inserted.toLowerCase())
        );
        setTallasData(filteredData);
    }

    const eliminarTallas = (idTalla) => {
        let data = {
            idTalla
        }
        helpAxios().post('/tallas/eliminar', data)
        .then(({error, type, message }) => {
            if (!error) {
                getTallaData()
            } 
            showAlert(type, message)
            
        })
    }

    const menuItems = [
        { key: 1, icon: <FileExcelOutlined />, label: 'Exportar', onClick: () => exportTable(columns, initialTallasData, 'Datos tallas', 'Tallas.xlsx') }
    ];
    
    return (
        <>
            <PageHeader modalVisible={() => setModalAddTallas(true)}/>
            <TablaTipoProductos
                keyTabla="idTipoProducto"
                Acciones={Acciones}
                showModalUpdate={()=> setModalUpdateTallas(true)}
                Header={Header}
                menuItems={menuItems}
                Footer={Footer}
                searchInput={searchInput}
                setModalDetalle={setModalDetalle}
                columns={columns}
                dataTabla={tallasData}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                eliminarTallas={eliminarTallas}
            />

            <ModalDetalle 
                modalDetalle={modalDetalle}
                setModalDetalle={setModalDetalle}
                selectedData={selectedData}
            />
            <ModalAgregar 
                isModalVisible={modalAddTallas}
                hideModal={()=> setModalAddTallas(false)}
                getTableData={getTallaData}
            />
            <ModalActualizar
                isModalVisible={modalUpdateTallas}
                hideModal={()=> setModalUpdateTallas(false)}
                getTableData={getTallaData}
                selectedData={selectedData}
            />
        </>
    )
}

export default Tallas