import { useEffect, useState } from 'react'
import { Button, Modal, Form, Input } from 'antd';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'
const Editar = ({isModalVisible, hideModal, getTableData, selectedData}) => {

  const [ perfilData, setPerfilData ] = useState('')
  const [form] = Form.useForm();

  const onFinish = (values) => {
    let dataUpdate = {
      id_usuario_tipo: selectedData.id_usuario_tipo,
      descripcion: values.descripcion
    }
    helpAxios().post('/perfil/update', dataUpdate)
    .then(({ error, type, message }) => {
      if(!error){
        form.resetFields();
        getTableData()
        hideModal()
      }
      showAlert(type, message)
    })
  };

  useEffect(() => {
    const { descripcion } = selectedData
    setPerfilData(descripcion)
  },[selectedData])


  return (
    <Modal 
      form={form}
      title="Actualizar perfil" 
      visible={isModalVisible} 
      footer={null} 
      onCancel={hideModal}
    >
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
        autoComplete="off"
        fields={[
          {name: ["descripcion"], value: perfilData},
        ]}
      >
        <Form.Item
          label="Perfil"
          name="descripcion"
          rules={[
            {
              required: true,
              message: 'Ingresa un perfil.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Actualizar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Editar