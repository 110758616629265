import { Modal} from 'antd'
import { useNavigate } from 'react-router-dom'
import {  ShoppingCartOutlined, ExclamationCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import './Popup.css'

const { confirm } = Modal;

const Popup = ({data, visible, x, y, onDelete, handleAddVentaVisible}) => {
    
    const navigate = useNavigate()

    return visible &&
        <ul className="popup" style={{left: `${x}px`, top: `${y}px`}}>
            <li onClick={() => navigate(`/productos/actualizar/${data.idProducto}`)}>
                <EditOutlined style={{marginRight: 5, color: 'blue'}}/> Editar
            </li>
            <li
                onClick={() =>{
                    confirm({
                        title: `Estas seguro que deseas eliminar el producto ${data.pr_nombre}?`,
                        icon: <ExclamationCircleOutlined />,
                        content: '¡Se borrara todo el detalle del producto!',
                        okText: 'Si',
                        okType: 'danger',
                        cancelText: 'No',
                        onOk() {
                            onDelete(data.idProducto)
                        },
                        onCancel() {
                        },
                      });
                }}
            ><DeleteOutlined style={{marginRight: 5, color: 'red'}}/> Eliminar
            </li>
            {data.stockProducto > 0 && 
                <li onClick={() => handleAddVentaVisible()}><ShoppingCartOutlined style={{marginRight: 5, color: 'green'}}/>Agregar a vendido</li>
            }
        </ul>
    }

export default Popup