import {useState, useEffect} from 'react'
import { Row,  Button, notification } from 'antd'
import { SwapRightOutlined } from '@ant-design/icons';
import { helpAxios } from '@helpers/helpAxios'
import TablaVentas from './TablaVentas'

import esES from "antd/es/locale/es_ES";
import { ConfigProvider, DatePicker as Picker } from "antd/es";
import dayjs from "dayjs";
import "dayjs/locale/es";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
dayjs.locale("es");


const ReporteVentas = () => {
    const [fechaFiltroInicio, setFechaFiltroInicio] = useState('1986/01/01')
    const [totalVentas, setTotalVentas] = useState(0)
    const [fechaFiltroFin, setFechaFiltroFin] = useState('4000/12/31')
    const [datosVentas, setDatosVentas] = useState([])

    useEffect(() => {
        getVentasData()
    }, []);

    const getVentasData = () => {
        let datos = {
            fechaInicio: fechaFiltroInicio,
            fechaFin: fechaFiltroFin
        }
        helpAxios().post('/ventas/reporte', datos)
        .then(({ error, type, message, data}) => {
            if(error){
                notification[type]({
                    message: message,
                    placement: 'bottomLeft'
                });
            }else{
                let total = data.ventas.reduce((a,b) => a + Number(b.monto_total), 0)
                setTotalVentas(total)
                setDatosVentas(data.ventas)
            }
        })
    }

    const handleFechaInicio = (data) => {
        if(data != null){
            const fechaInicio = data.format('YYYY/MM/DD')
            setFechaFiltroInicio(fechaInicio)
        }else{
            setFechaFiltroInicio('1986/01/01')
        }
    }
    const handleFechaTermino = (data) => {
        if(data != null){
            const fechaFin = data.format('YYYY/MM/DD')
            setFechaFiltroFin(fechaFin) 
        }else{
            setFechaFiltroFin('4000/12/31') 
        }
    }

    return (
        <>
            <Row justify='center'>
                <label htmlFor="fechasFiltrar">Fechas a filtrar</label> 
                <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                    <ConfigProvider locale={esES}>
                    <Picker 
                        format="YYYY/MM/DD"
                        placeholder='Fecha inicio'
                        style={{
                            width: '15%'
                        }}
                        onChange={handleFechaInicio}
                    />
                    &nbsp;<SwapRightOutlined style={{ color: 'gray'}}/>&nbsp;
                    <Picker 
                        format="YYYY/MM/DD"
                        placeholder='Fecha Fin'
                        style={{
                            width: '15%'
                        }}
                        onChange={handleFechaTermino}
                    />
                    </ConfigProvider>
                    <Button 
                        onClick={getVentasData}
                        style={{ marginLeft: '10px'}}
                        type="primary"
                    >
                        Filtrar
                    </Button>
                </div>
            </Row>
            <Row justify='center'>
                <TablaVentas 
                    datosVentas={datosVentas}
                    totalVentas={totalVentas}
                />
            </Row>
        </>
    )
}

export default ReporteVentas