import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    UserOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
const { Header } = Layout;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const TopMenu = () => {
    let [nombreUsuario, setNombreUsuario] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
        handleChangeUserName()
    }, [])

    const cerrarSesion = () => {
        localStorage.clear();
        window.location.href = '/login'
    }

    const handleChangeUserName = () => {
        const userName = localStorage.getItem('userName')
        setNombreUsuario(userName)
    }

    let subMenu = [
        {
            key: 'subMenu',
            icon: <UserOutlined />,
            label: nombreUsuario,
            children: [
                { key: "subMenu/perfil", icon: <FontAwesomeIcon icon={solid('pen-to-square')} />, onClick: () => navigate('/perfil/' + localStorage.getItem('idUsuario')), label: 'Mis datos' },
                { key: "subMenu/sesion", icon: <LogoutOutlined />, onClick: () => cerrarSesion(), label: 'Cerrar Sesión' }
            ]
        },
    ]
    return (
        <Header className="site-layout-sub-header-background" style={{ textAlign: 'right', position: 'fixed', zIndex: 10, width: '100%', padding: 0, background: '#ffffff', boxShadow: '0px 2px 42px -9px rgba(0,0,0,0.3)' }} >
            <Menu items={subMenu} mode="horizontal" />
        </Header>
    )
}

export default TopMenu