import { useNavigate } from 'react-router-dom'
import './FormLogin.css'
import { Form, Input, Button, message, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'
import useMediaQuery from '@helpers/useMediaQuery'; 

const FormLogin = ({changeAuth}) => {
    document.title = "Login"
    const matches = useMediaQuery('(min-width: 600px)')
    const navigate = useNavigate()

    const onFinish = (values) => {
        let data = {
                "userName": values.userName,
                "password": values.password
        }
        helpAxios().post('/auth/signIn', data)
        .then((res) => {
            const { codeMessage, token, idUser, nombreUsuario, idUsuario, isAdmin, estado, userData} = res
            if(codeMessage === 1){
                if(estado === 1){
                    localStorage.setItem('auth', token)
                    localStorage.setItem('userName', nombreUsuario)
                    localStorage.setItem('idUsuario', idUsuario)
                    localStorage.setItem('isAdmin', isAdmin)
                    localStorage.setItem('userData', JSON.stringify(userData))
                    message.loading('Bienvenido! Redirigiendo...', 1)
                    .then(() => {
                        changeAuth(idUser)
                        navigate('/dashboard')
                    })
                }else{
                    showAlert('error', 'Ya no tiene acceso a este sistema.')
                }
            }
            if(codeMessage === 2){
                showAlert('error', res.message)
            }
            if(codeMessage === 3){
                showAlert('error', res.message)
            }
        })
        
    };

    return (
        <div className="content-user-login" style={{top: matches ? '35%' : '20%'}} justify="center" align="middle">
            <div className="login-title">Inventario Daga</div>
            <label>Ingresa tu nombre de usuario y contraseña para comenzar</label>
            <Space/>
            <Form
                name="normal_login"
                className="login-form"
                onFinish={onFinish}
            >
                <Form.Item
                    name="userName"
                    rules={[
                        {
                            required: true,
                            message: 'Ingresa tu nombre de usuario!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Usuario" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Ingresa tu contraseña!',
                        },
                    ]}
                    
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Contraseña"
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Iniciar Sesion
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default FormLogin