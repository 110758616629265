import { Row, Col, Modal } from 'antd';

const ModalDetalle = ({ ...args }) => {
    console.log(args)
    return (
        <Modal
            centered
            visible={args.modalDetalle}
            title={`Tipo producto ${args.selectedData.nombreTipo}`}
            onOk={() => args.setModalDetalle(false)}
            onCancel={() => args.setModalDetalle(false)}
            footer={null}
            width={'30%'}
        >
             <Row type='flex' align="right" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={12}>
                    Creado:
                </Col>
                <Col className="gutter-row" span={12}>
                    {args.selectedData.createdBy}
                </Col>
            </Row>
            <Row align="middle" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={12}>
                    Creado a las:
                </Col>
                <Col className="gutter-row" span={12}>
                    {args.selectedData.createdAt}
                </Col>
            </Row>
            <Row justify="space-around" align="middle" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={12}>
                    Modificado por:
                </Col>
                <Col className="gutter-row" span={12}>
                    {args.selectedData.updatedBy === null ? 'Tipo producto no ha sido modificado.' : args.selectedData.updatedBy}
                </Col> 
            </Row>
            <Row align="middle" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={12}>
                    Modificado:
                </Col>
                <Col className="gutter-row" span={12}>
                    {args.selectedData.updatedAt === null ? 'Tipo producto no ha sido modificado.' : args.selectedData.updatedAt}
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalDetalle

