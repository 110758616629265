import { Button, Form, Input, Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'
const EditPassword = () => {

    const { id } = useParams()

    const onFinish = (values) => {
        values.userName = id
        if (values.passwords.primera === values.passwords.repite) {
            helpAxios().post('/users/edit/password', values)
                .then(({ message, type }) => {
                    showAlert(type, message)
                })
        } else {
            showAlert('error', 'Las contraseñas no coinciden.')
        }
    }
  return (
    <Form
        layout="vertical"
        name="complex-form"
        onFinish={onFinish}
    >
            <Row gutter={16} align="middle">
                <Col className="gutter-row" span={16}>
                    <Form.Item label="Contraseña">
                        <Input.Group compact>
                            <Form.Item
                                name={['passwords', 'primera']}
                                noStyle
                                rules={[{ required: true, message: 'Campo contraseña es requerido.' }]}
                            >
                                <Input.Password style={{ width: '50%' }} placeholder="Ingresa contraseña." />
                            </Form.Item>
                            <Form.Item
                                name={['passwords', 'repite']}
                                noStyle
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input.Password style={{ width: '50%' }} placeholder="Repite contraseña" />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                    <Form.Item label=" " colon={false}>
                        <Button type="primary" htmlType="submit">
                            Actualizar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
  )
}

export default EditPassword