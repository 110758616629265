import  {  useState, useEffect } from 'react';
import { Row } from 'antd';

import { helpAxios } from '@helpers/helpAxios'
import FormularioActualizarDetalles from './FormularioActualizarDetalles'
import FormAddDetail from '@components/Gestores/Productos/Actualizar/NewUpdateFormProduct/FormAddDetail'
import { Card, Alert, } from 'antd'
import { ArrowDownOutlined  } from '@ant-design/icons';

const ContainerUpdateDetails = ({idProducto}) => {

  const [tallasProductos, setTallasProductos] = useState([]);
  const [productData, setProductData] = useState([]);

  const ObtenerDatosTallas = () => {
    helpAxios().get('/productos/tallas')
      .then(({data}) => {
        setTallasProductos(data.tallasProducto)
      })
  }

  const ObtenerDatosProducto = () => {
    helpAxios().get('/productos/' + idProducto)
      .then(({ data }) => {
        let { dataDetail } = data
        setProductData(dataDetail)
      })
  }

  useEffect(() => {
    ObtenerDatosTallas()
    ObtenerDatosProducto()
  }, []);
  return (
      <>
        <Row type="flex" justify="center" align="middle">
          { 
            productData.length === 0 &&
              <Card className='cardNoDetails' type="flex" justify="center" align="middle" style={{ borderRadius: 15}}>
                  <Alert message={<>Este es uno de los productos que está sin stock, o a quedado sin stock. Para agregar stock presiona aqui.</>} style={{borderRadius: 15}} type="warning" showIcon /><br/>
                  <ArrowDownOutlined/>
              </Card>
          }
        </Row>
          {productData.map((data,index) => (
              <FormularioActualizarDetalles key={index} datosDetalle={data} ObtenerDatosProducto={ObtenerDatosProducto} tallasProductos={tallasProductos} idProducto={idProducto} />
          ))}
          <FormAddDetail 
              idProducto={idProducto} 
              tipoAccion="update"
          />
          
      </>
  );
};

export default ContainerUpdateDetails