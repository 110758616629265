import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { capitalize } from '@helpers/helpers'
const { confirm } = Modal;
const Acciones = ({ ...args }) => {
  console.log('selectedData',args.selectedData)
  return (
    <>
      {
        args.selectedData.id_usuario_tipo !== 1 &&
        <>
          <li onClick={() => args.showModalUpdate()}>
            <i className="fa-solid fa-pen" style={{ marginRight: 5, color: 'blue' }}></i>Editar
          </li>
          <li onClick={() => {
                confirm({
                    title: `¿Estás seguro que deseas eliminar el tipo producto ${capitalize(args.selectedData.nombreTipo)}?`,
                    icon: <ExclamationCircleOutlined />,
                    content: '',
                    okText: 'Si',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        args.eliminarTipoProducto(args.selectedData.idTipoProducto)
                    },
                });
            }}>
              <i className="fa-solid fa-trash" style={{ marginRight: 5, color: 'red' }}></i> Eliminar
            </li>
        </>
      }
    </>
  )
}
export default Acciones