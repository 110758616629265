import { useState, useEffect } from 'react'
import { FileExcelOutlined } from '@ant-design/icons';

import { helpAxios } from '@helpers/helpAxios'
import { exportTable, showAlert } from '@helpers/helpers'

import TablaTipoProductos from '@components/Global/TablaClickSecundario/Tabla'
import Header from '@components/Global/HeaderTable/Header'
import Footer from './Listado/Footer/Footer'
import Acciones from './Listado/Acciones/Acciones'
import PageHeader from './Listado/PageHeader/PageHeader'
import ModalAgregar from './Agregar/AgregarTipoProducto'
import ModalActualizar from './Actualizar/Actualizar'

import ModalDetalle from './ModalDetalle/ModalDetalle';

const TipoProducto = () => {
    const [tipoProductoData, setTipoProductoData] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [initialTipoProductoData, setInitialTipoProductoData] = useState([])
    const [modalAddTipoProducto, setModalAddTipoProducto] = useState(false)
    const [modalUpdateTipoProducto, setModalUpdateTipoProducto] = useState(false)
    const [modalDetalle, setModalDetalle] = useState(false)

    const columns = [
        {
            title: 'ID Tipo Producto',
            dataIndex: 'idTipoProducto',
            key: 'idTipoProducto',
            width: '33%',
            sorter: (a, b) => a.idTipoProducto - b.idTipoProducto
        },
        {
            title: 'Tipo Producto',
            dataIndex: 'nombreTipo',
            key: 'nombreTipo',
            width: '33%',
            sorter: (a, b) => a.nombreTipo.localeCompare(b.nombreTipo)
        }
    ];

    useEffect(() => {
        getTipoProductoData()
    }, []);

    const getTipoProductoData = () => {
        helpAxios().get('/tipoProducto')
        .then(({ error, type, message, data }) => {
            if (error) {
                showAlert(type, message)
            } else {
                setTipoProductoData(data)
                setInitialTipoProductoData(data)
            }
        })
    }

    const searchInput = (inserted) => {
        const filteredData = initialTipoProductoData.filter(entry =>
            String(entry.idTipoProducto).toLowerCase().includes(inserted.toLowerCase()) ||
            entry.nombreTipo.toLowerCase().includes(inserted.toLowerCase())
        );
        setTipoProductoData(filteredData);
    }

    const eliminarTipoProducto = (idTipoProducto) => {
        let data = {
            idTipoProducto
        }
        helpAxios().post('/tipoProducto/eliminar', data)
        .then(({error, type, message }) => {
            if (!error) {
                getTipoProductoData()
            } 
            showAlert(type, message)
            
        })
    }

    const menuItems = [
        { key: 1, icon: <FileExcelOutlined />, label: 'Exportar', onClick: () => exportTable(columns, initialTipoProductoData, 'Datos tipo productos', 'TipoProductos.xlsx') }
    ];
    
    return (
        <>
            <PageHeader modalVisible={() => setModalAddTipoProducto(true)}/>
            <TablaTipoProductos
                keyTabla="idTipoProducto"
                Acciones={Acciones}
                showModalUpdate={()=> setModalUpdateTipoProducto(true)}
                Header={Header}
                menuItems={menuItems}
                Footer={Footer}
                searchInput={searchInput}
                columns={columns}
                dataTabla={tipoProductoData}
                setModalDetalle={setModalDetalle}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                eliminarTipoProducto={eliminarTipoProducto}
            />

            <ModalDetalle 
                modalDetalle={modalDetalle}
                setModalDetalle={setModalDetalle}
                selectedData={selectedData}
            />

            <ModalAgregar 
                isModalVisible={modalAddTipoProducto}
                hideModal={()=> setModalAddTipoProducto(false)}
                getTableData={getTipoProductoData}
            />
            <ModalActualizar
                isModalVisible={modalUpdateTipoProducto}
                hideModal={()=> setModalUpdateTipoProducto(false)}
                getTableData={getTipoProductoData}
                selectedData={selectedData}
            />
        </>
    )
}

export default TipoProducto