import { Button, Modal, Form, Input } from 'antd';
import { useState, useEffect } from 'react';
import { helpAxios } from '@helpers/helpAxios'
import { showAlert } from '@helpers/helpers'

const ActualizarProducto = ({ isModalVisible, hideModal, getTableData, selectedData }) => {
    
    const [form] = Form.useForm();
    const [tallaData, setTallaData] = useState({ ...selectedData })

    useEffect(() => {
        if(isModalVisible){
            setTallaData(selectedData)
        }
    }, [isModalVisible]);

    const onFinish = (values) => {
        values.idTalla = selectedData.idTalla
        helpAxios().post('/tallas/actualizar', values)
        .then(({ error, type, message }) => {
            showAlert(type, message)
            if(!error){
                hideModal()
                getTableData()
            }
        })
    }

    const onChangeTalla = ({ target }) => {
        const { value } = target
        console.log(value)
        let newData = {
            idTalla: selectedData.idTalla,
            nombreTalla: value
        }
        setTallaData(newData)
    }

    return (

        <Modal 
            title="Actualizar talla" 
            footer={null} 
            visible={isModalVisible} 
            onCancel={() => {
                form.resetFields();
                hideModal()
            }} 
        >
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                onFinish={onFinish}
                autoComplete="off"
                fields={[
                    {name: ["nombreTalla"], value: tallaData.nombreTalla},
                ]}
            >
                <Form.Item
                    label="Talla"
                    name="nombreTalla"
                    rules={[
                        {
                            required: true,
                            message: 'Ingrese talla.',
                        },
                    ]}
                >
                    <Input 
                        onChange={onChangeTalla}
                    />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button disabled={tallaData.nombreTalla == selectedData.nombreTalla} style={{ marginRight: "10px" }} type="primary" htmlType="submit">
                        Actualizar
                    </Button>
                    <Button onClick={hideModal}>
                        Cancelar
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )

}

export default ActualizarProducto
